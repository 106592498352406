import React, { useCallback } from "react";
import axios from "axios";
import { useState, useRef, useEffect, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel.jsx";
import HttpCliente from "../../../services/HttpCliente";
import ModalGeneral, { ModalGeneralType } from "../../../components/modal/modalGeneral";
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import moment from "moment";

interface State {
  query_type: number;
  priority_customer: boolean;
  priority_reniec: boolean;
  input_data: string;
}

const initState = {
  query_type: 1,
  priority_customer: true,
  priority_reniec: true,
  input_data: "",
};

//alert info
interface ModalGeneralParam {
  title?: string;
  sub_title?: string;
  type?: ModalGeneralType;
  modalGeneralShow?: boolean;
}
const initModalGeneralParam = {
  title: "",
  sub_title: "",
  type: undefined,
  modalGeneralShow: false,
};
//end alert info

interface PaginationReq {
  page: number,
  per_page: number,
  sort: string,
  direction: string
}

const initPaginationReq = {
  page: 1,
  per_page: 30,
  sort: "",
  direction: ""
}

interface Pagination {
  per_page: number,
  data: any,
  current_page: number,
  last_page: number,
  total_count: number,
  completedCount: number,
  pendinCount: number
}

interface ErrorResponse {
  errors: Record<string, string[]>;
}

interface Response {
  code: number,
  body: any
}

interface RequestExport {
  Id: number
}

interface InputFields {
  customerNumber: string;
  customerName: string;
  customerDob: string;
}

const ManualCEQuery = () => {
  const [state, setState] = useState<State>(initState);
  const [ModalGeneralParam, setModalGeneralParam] = useState<ModalGeneralParam>(initModalGeneralParam);
  const [paginationReq, setpaginationReq] = useState<PaginationReq>(initPaginationReq);
  let [outputDataMQ, setOutputDataMQ] = useState<any[]>([]);
  const [showProcessing, setShowProcessing] = useState(false);
  const [showProcessingRefresh, setShowProcessingRefresh] = useState(false);
  const [per_page, set_per_page] = React.useState(30);
  const [page, set_page] = React.useState(1);
  const tablaRef = useRef<HTMLTableElement | null>(null);
  const [existProcessRequest, setExistProcessRequest] = useState(null);
  const [existPendingMigraciones, setExistPendingMigraciones] = useState(null);
  let [paginationData, setPaginationData] = React.useState<Pagination>();



  const txtInputData = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (txtInputData.current) txtInputData.current.focus();
  }, [txtInputData]);

  const pendingOutputs = (): boolean => {
    if (!tablaRef.current) return false;

    const filas: HTMLCollectionOf<HTMLTableRowElement> = tablaRef.current.getElementsByTagName('tr');
    let existeValor: boolean = false;

    for (let i = 0; i < filas.length; i++) {
      const celdas: HTMLCollectionOf<HTMLTableCellElement> = filas[i].getElementsByTagName('td');
      for (let j = 0; j < celdas.length; j++) {
        if (celdas[j].innerText === "Pending") {
          existeValor = true;
          break;
        }
      }
      if (existeValor) break;
    }

    return existeValor;
  };

  const callOutputList = (pagination: PaginationReq) => {
    HttpCliente.post(`/customer/manual/carnes-extranjerias/list`, pagination)
      .then((responseList) => {
        setOutputDataMQ(responseList.data.body.data);
        setPaginationData(responseList.data.body);
        const resp = responseList.data.body.data;
        const _existProcessRequest = resp.some((x: { request_Status: string; }) => x.request_Status === "Process");
        const _existPendingMigraciones = resp.some((x: { migraciones_answer: string; }) => x.migraciones_answer === "Pending");
        setExistProcessRequest(_existProcessRequest);
        setExistPendingMigraciones(_existPendingMigraciones);
      })
      .catch((error) => {
        error.response.data.errors ?
          Swal.fire({
            icon: 'error',
            title: error.response.data.title,
            html: formatErrors(error.response.data.errors),
          })
          :
          showModalGeneralParam(true, "Failed", `${"Failed: Something went wrong. Please contact support. Details: Error code " + error.response.data.status}`, "warning");
      });
  }

  const callList = useCallback(() => {

    const isPending = pendingOutputs();
    const paginationResquest = {
      page: page,
      per_page: per_page,
      sort: "",
      direction: ""
    };

    if (isPending) {
      callOutputList(paginationResquest);
    } else if (page !== paginationReq.page || per_page !== paginationReq.per_page) {
      callOutputList(paginationResquest);
      setpaginationReq(paginationResquest);
    }
  }, [page, per_page, paginationReq]);

  React.useEffect(() => {
    callList();
    const intervalId = setInterval(callList, 60000);
    return () => clearInterval(intervalId);
  }, [callList]);

  React.useEffect(() => {
    callOutputList(paginationReq);
  }, []);

  const handleInputChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLTextAreaElement>) => setState({ ...state, [name]: value });

  const inputValidationsErrors = (errors: any) => {
    const errorList = errors.map((error: any) => `Row ${error.row}: ${error.note}`).join('\n');
    return errorList;
  };

  const convertDateFormat = (dateString: any) => {
    const [day, month, year] = dateString.split('/');
    return `${year}${month}${day}`;
  };

  function isValidDateFormat(dateString: string): boolean {
    // Expresión regular para validar "dd/mm/yyyy"
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;
    return regex.test(dateString);
  }

  const handleValidateData = () => {
    const inputData = state.input_data;

    //Validations
    if (inputData.trim() === "") {
      showModalGeneralParam(true, "Input Data is requerid.", "", "info");
      return;
    }
    //

    const lstRow = inputData.split("\n");
    const arrayDataByCE: any = [];
    let sentData = true;
    let nroRow = 1;
    let message: any = [];
    lstRow.some(element => {
      let fiels = element.split(",");

      let continueLoop = true;
      let invalidDate = false;
      if (fiels.length != 1) {

        if (fiels.length != 3) {
          continueLoop = false;
          message.push({
            row: nroRow,
            note: "The row does not have the correct number of fields. Please use the following format: CE number,Customer Name,Customer DoB."
          });
        } else {
          if (fiels[0].trim() === "" || fiels[1].trim() === "" || fiels[2].trim() === "") {
            continueLoop = false;
            message.push({
              row: nroRow,
              note: "The row has one or more empty fields."
            });
          } else {
            if (fiels[1].trim().split(" ").length <= 1) {
              continueLoop = false;
              message.push({
                row: nroRow,
                note: "The customer name needs to have name and lastname."
              });
            }else {
              const name = fiels[1].replace(/\s+/g, ' ').trim();
              if(name.length > 100){
                continueLoop = false;
                message.push({
                  row: nroRow,
                  note: "Customer name is too long, please use a maximum of 100 characters."
                });
              }
            }
          }

          if (!isValidDateFormat(fiels[2].trim())) {
            continueLoop = false;
            invalidDate = true;
          }
        }

        if (!continueLoop) {
          if (invalidDate) {
            showModalGeneralParam(true, "Some row doesn't have the correct date format. (dd/mm/yyyy)", `Row ${nroRow}: ${(element == "" ? "it is empty" : element)}`, "info");
          } else {
            Swal.fire({
              title: 'Errors found. Details:',
              icon: 'info',
              text: inputValidationsErrors(message),
              confirmButtonText: 'Ok'
            });
          }
          sentData = false;
          return true;
        }

        let isUnique = arrayDataByCE.every((item: InputFields) => {
          return item.customerNumber !== fiels[0] ||
            item.customerName !== fiels[1].replace(/\s+/g, ' ').trim() ||
            item.customerDob !== convertDateFormat(fiels[2].trim());
        });

        if (isUnique) {
          arrayDataByCE.push({
            customerNumber: fiels[0].trim(),
            customerName: fiels[1].replace(/\s+/g, ' ').trim(),
            customerDob: convertDateFormat(fiels[2].trim())
          });
        }
      }
      nroRow += 1;
    });

    if (sentData) {
      if (outputDataMQ.length !== 0) {
        Swal.fire({
          title: 'Are you sure?',
          text: "There are records in the Output of Validation section, if you proceed this records will be discarded, are you sure you want to proceed?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            clearOutput();
            queryByName(arrayDataByCE);
          }
        });
      } else {
        queryByName(arrayDataByCE);
      }
    }
  };

  const queryByName = (arrayDataByCE: any) => {
    const sigData = {
      parnertID: "1",
      carnesExtranjeria: arrayDataByCE,
    };

    setShowProcessing(true);
    HttpCliente.post(`/customer/manual/carnes-extranjerias`, sigData)
      .then((response) => {
        if (response.data.code === 201) {
          callOutputList(paginationReq);
          setShowProcessing(false);
        } else {
          showModalGeneralParam(true, "Failed", `Failed: ${response.data.body ? response.data.body : ""
            }`, "warning");
          setShowProcessing(false);
        }
      })
      .catch((error) => {
        setShowProcessing(false);
        if(error.response){
          error.response.data.errors ?
          Swal.fire({
            icon: 'error',
            title: error.response.data.title,
            html: formatErrors(error.response.data.errors),
          })
          :
          showModalGeneralParam(true, "Failed", `${"Failed: Something went wrong. Please contact support. Details: Error code " + error.response.data.status}`, "warning");       
        }else{
          showModalGeneralParam(true, "Validations in process.", `Please be patient.`, "info");
          callOutputList(paginationReq);
        }
      });
  }

  const formatErrors = (errorResponse: ErrorResponse) => {
    if (!errorResponse) return '';

    return Object.entries(errorResponse).map(([field, errors]) => {
      const formattedField = field.replace(/\[(\d+)\]/g, ' $1 ');
      return `${formattedField}:<br>${errors.join('<br>')}`;
    }).join('<br><br>');
  };

  const gotoPage = (value: any) => {
    set_page(value);
  };
  const nextPage = () => {
    if (typeof paginationData !== "undefined") {
      if (page < paginationData.last_page) {
        set_page(page + 1);
      }
    }
  };
  const previousPage = () => {
    if (page > 1) set_page(page - 1);
  };

  const loadingAnimation = () => {
    return (
      <tr>
        <td colSpan={9}>
          <div style={{ padding: "15vh 0% 15vh 38vw" }}>
            <ReactLoading type="spin" color="#00aeab" height={65} width={65} />
          </div>
        </td>
      </tr>
    );
  };


  const exportButtonAction = () => {
    const hideClass = "hide";
    if (outputDataMQ.length !== 0) {
      document.getElementById("prgLoading")!.className = "show";
      axios.get(`${process.env.REACT_APP_KYC_API_ENDPOINT}/customer/manual/carnes-extranjerias/export/?time_zone_off_set=${-(
          new Date().getTimezoneOffset() / 60
        )}`, { responseType: 'json' })
        .then((response) => {

          if (response.data.code === 200) {
            const binaryString = window.atob(response.data.body);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);

            for (let i = 0; i < binaryLen; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Manual-CE-Validation.xlsx');

            document.body.appendChild(link);
            link.click();

            link.parentNode!.removeChild(link);
            window.URL.revokeObjectURL(url);

            document.getElementById("prgLoading")!.className = hideClass;

          } else {
            showModalGeneralParam(true, "Failed", `Failed: : Export file`, "warning");
          }

        })
        .catch((error) => {
          document.getElementById("prgLoading")!.className = hideClass;
          error.response.data.errors ?
            Swal.fire({
              icon: 'error',
              title: error.response.data.title,
              html: formatErrors(error.response.data.errors),
            })
            :
            showModalGeneralParam(true, "Failed", `${"Failed: Something went wrong. Please contact support. Details: Error code " + error.response.data.status}`, "warning");
        });
    } else {
      showModalGeneralParam(true, "There are no records to export.", "", "info");
    }
  };

  const refreshButtonAction = () => {
    setShowProcessingRefresh(true);
  };

  useEffect(() => {
    if (showProcessingRefresh) {
      callOutputList(paginationReq);
      setShowProcessingRefresh(false);
    }
  }, [showProcessingRefresh]);

  const clearOutput = () => {
    if (outputDataMQ.length !== 0) {

      const hideIds: RequestExport[] = outputDataMQ.map(item => ({ Id: item.id }))
      const listID = { ListIdHide: hideIds };

      axios.patch<Response>(`${process.env.REACT_APP_KYC_API_ENDPOINT}/customer/manual/carnes-extranjerias/hide`, listID)
        .then((response) => {
          if (response.data.code === 204) {
            setOutputDataMQ([]);
          } else {
            showModalGeneralParam(true, "Failed", `Failed: ${response.data.body ? response.data.body : ""
              }`, "warning");
          }
        })
        .catch((error) => {
          error.response.data.errors ?
            Swal.fire({
              icon: 'error',
              title: error.response.data.title,
              html: formatErrors(error.response.data.errors),
            })
            :
            showModalGeneralParam(true, "Failed", `${"Failed: Something went wrong. Please contact support. Details: Error code " + error.response.data.status}`, "warning");
        });
    }
  };

  const submitButtonAction = () => {
    if (outputDataMQ.length !== 0) {
      Swal.fire({
        title: 'Are you sure you want to submit',
        text: "these request records?",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          setShowProcessing(true);
          const hideIds: RequestExport[] = outputDataMQ.map(item => ({ Id: item.id }))
          const listID = { ListIds: hideIds };

          axios.patch<Response>(`${process.env.REACT_APP_KYC_API_ENDPOINT}/customer/manual/carnes-extranjerias/status/send`, listID)
            .then((response) => {
              if (response.data.code === 204) {
                setShowProcessing(false);
                refreshButtonAction();
              } else {
                showModalGeneralParam(true, "Failed", `Failed: ${response.data.body ? response.data.body : ""
                  }`, "warning");
                setShowProcessing(false);
              }
            })
            .catch((error) => {
              setShowProcessing(false);
              error.response.data.errors ?
                Swal.fire({
                  icon: 'error',
                  title: error.response.data.title,
                  html: formatErrors(error.response.data.errors),
                })
                :
                showModalGeneralParam(true, "Failed", `${"Failed: Something went wrong. Please contact support. Details: Error code " + error.response.data.status}`, "warning");
            });
        }
      });
    } else {
      showModalGeneralParam(true, "There are no records to submit.", "", "info");
    }
  };

  const showModalGeneralParam = (
    modalGeneralShow: boolean,
    title?: string,
    sub_title?: string,
    type?: ModalGeneralType | undefined
  ) => {
    const dataInfo: ModalGeneralParam = {
      title: title,
      sub_title: sub_title,
      modalGeneralShow: modalGeneralShow,
      type: type
    };
    setModalGeneralParam(dataInfo);
  };

  const labelCompletedRequestStyle = {
    color: '#00acac',
  };

  const labelPendingRequestStyle = {
    color: '#f59c1a',
  };

  const labelValidStyle = {
    backgroundColor: '#00acac',
    color: '#fff',
    padding: '2px 5px',
    borderRadius: '5px',
    display: 'inline-block'
  };

  const labelInValidStyle = {
    backgroundColor: '#ff5b57',
    color: '#fff',
    padding: '2px 5px',
    borderRadius: '5px',
    display: 'inline-block'
  };

  const labelPendingStyle = {
    backgroundColor: '#f59c1a',
    color: '#fff',
    padding: '2px 5px',
    borderRadius: '5px',
    display: 'inline-block'
  };

  const labelProcessStyle = {
    backgroundColor: '#f2f3f4',
    color: '#414053',
    padding: '2px 5px',
    borderRadius: '5px',
    display: 'inline-block'
  };

  const btnExportStyle = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: '0',
    marginBottom: '10px'
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
  };

  const getLabelStyle = (status: any) => {
    if (status === 'Valid') {
      return labelValidStyle;
    } else if (status === 'Pending') {
      return labelPendingStyle;
    } else if (status === 'Invalid') {
      return labelInValidStyle;
    } else if (status === 'Process') {
      return labelProcessStyle;
    } else if (status === 'Sent') {
      return labelValidStyle;
    } else if (status === 'Archive') {
      return labelValidStyle;
    } else {
      return labelPendingStyle;
    }
  }

  return (
    <div id="animate">
      <h1 className="page-header">Manual CE</h1>
      <Panel>
        <PanelHeader noButton>Input Data</PanelHeader>
        <PanelBody>
          <label className="form-label"><span>{state.query_type === 1 ? "CE, Customer Name, DOB" : "Payout ID"}</span></label>

          <div className="row">
            <div className="form-group col-md-10">
              <textarea
                name="input_data"
                className="form-control"
                rows={5}
                value={state.input_data}
                ref={txtInputData}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group col-md-2">
              <button
                className="btn btn-success btn-lg"
                onClick={handleValidateData}
              >
                Validate
              </button>
            </div>
          </div>
        </PanelBody>
        <PanelHeader noButton>Output of Validation</PanelHeader>
        <PanelBody>
          <div>
            <label style={labelValidStyle}>Completed Count:</label>   <span style={{ fontWeight: 'bold', color: 'black', marginRight: '5px' }}>{paginationData?.completedCount}</span>
            <label style={labelPendingStyle}>Pending Count:</label>   <span style={{ fontWeight: 'bold', color: 'black' }}>{paginationData?.pendinCount}</span>
            <button
              style={btnExportStyle}
              className="panel-iconbutton"
              onClick={() => {
                exportButtonAction();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                viewBox="0 0 24 24"
                fill="white"
                width="20px"
                height="20px"
              >
                <g>
                  <path d="M0,0h24v24H0V0z" fill="none" />
                </g>
                <g>
                  <g>
                    <path d="M18,2h-8L4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M18,4v16H6V8.83L10.83,4H18z" />
                    <path d="M16,13l-4,4l-4-4l1.41-1.41L11,13.17V9.02L13,9v4.17l1.59-1.59L16,13z" />
                  </g>
                </g>
              </svg>
              Export
              <div id="prgLoading" className="hide"><ReactLoading type="spin" color="white" height={15} width={15} /></div>
            </button>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered" ref={tablaRef}>
              <thead>
                <tr>
                  <td style={{ width: "5%" }}>
                    <strong>Options</strong>
                  </td>
                  <td style={{ width: "10%" }}>
                    <strong>CE</strong>
                  </td>
                  <td style={{ width: "25%" }}>
                    <strong>Full Name</strong>
                  </td>
                  <td style={{ width: "25%" }}>
                    <strong>Migration full name</strong>
                  </td>
                  <td style={{ width: "25%" }}>
                    <strong>Migration DOB</strong>
                  </td>
                  <td style={{ width: "10%" }}>
                    <strong>Migration nationality</strong>
                  </td>
                  <td style={{ width: "5%" }}>
                    <strong>CE Validation</strong>
                  </td>
                  <td style={{ width: "5%" }}>
                    <strong>Name Validation</strong>
                  </td>
                  <td style={{ width: "5%" }}>
                    <strong>DOB Validation</strong>
                  </td>
                  <td style={{ width: "5%" }}>
                    <strong>KYC Status</strong>
                  </td>
                  <td style={{ width: "5%" }}>
                    <strong>Migraciones Status</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                {typeof paginationData !== `undefined`
                  ? typeof outputDataMQ !== `undefined`
                    ? outputDataMQ.map((value: any, index: any) => {
                      return (
                        <tr className={index % 2 !== 0 ? "table-data" : "table-data-alter"}>
                          <td className="text-center">
                            {value.id !== 0 && (
                              <Link to={`${process.env.REACT_APP_Sub_Domain}/request/pending/search/view/${value.id}`} target="_blank" rel="noopener noreferrer">
                                <button
                                  className="form-button-search"
                                  onClick={() => {
                                    //searchIconHandler(line);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="-3 -2 30 30"
                                    fill="white"
                                    width="18px"
                                    height="18px"
                                  >
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                  </svg>
                                </button>
                              </Link>
                            )}&nbsp;
                          </td>
                          <td>{value.customer_id_number}</td>
                          <td>{value.customer_fullname}</td>
                          <td>{value.validator_customer_fullname}</td>
                          <td>{value.validator_customer_dbo ? moment(value.validator_customer_dbo).format('DD/MM/YYYY') : ""}</td>
                          <td>{value.validator_customer_nationality}</td>
                          <td className="text-center"><label style={getLabelStyle(value.kyc_id_status)}>{value.kyc_id_status}</label></td>
                          <td className="text-center"><label style={getLabelStyle(value.kyc_name_status)}>{value.kyc_name_status}</label></td>
                          <td className="text-center"><label style={getLabelStyle(value.kyc_dob_Status)}>{value.kyc_dob_Status}</label></td>
                          <td className="text-center"><label >{value.request_Status}</label></td>
                          <td className="text-center">
                            {value.migraciones_answer == 'Completed' ? (
                              <i className="fa-solid fa-circle-check fa-2x" style={labelCompletedRequestStyle}></i>
                            ) : (
                              <i className="fa-solid fa-clock fa-2x" style={labelPendingRequestStyle}></i>
                            )}
                          </td>
                        </tr>
                      );
                    })
                    : null
                  : loadingAnimation()}
              </tbody>
            </table>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <ul className="pagination mb-0">
              <li className="page-item">
                <button className="page-link" onClick={() => gotoPage(1)}>
                  <i className="fa fa-angle-double-left"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => previousPage()}
                >
                  <i className="fa fa-angle-left"></i>
                </button>
              </li>
              <li className="page-item d-flex align-items-center px-2">
                <div>
                  Page{" "}
                  {typeof paginationData !== "undefined" ? (
                    typeof paginationData.data !== "undefined" ? (
                      <strong>
                        {page} of {paginationData.last_page}
                      </strong>
                    ) : (
                      <strong>{page} of 1</strong>
                    )
                  ) : (
                    <strong>{page} of 1</strong>
                  )}
                </div>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => nextPage()}
                >
                  <i className="fa fa-angle-right"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link mx-2"
                  onClick={() => gotoPage(paginationData!.last_page)}
                >
                  <i className="fa fa-angle-double-right"></i>
                </button>
              </li>
            </ul>
            {typeof paginationData !== "undefined" ? (
              typeof paginationData.data !== "undefined" &&
                paginationData.data !== null ? (
                <strong>
                  <div className="ml-3 mr-1 mx-2">{`Showing ${paginationData.per_page * paginationData.current_page -
                    (paginationData.per_page - 1)
                    } to ${paginationData.per_page * paginationData.current_page
                    } of ${paginationData.total_count} entries`}</div>
                </strong>
              ) : (
                <div className="ml-3 mr-1">{`Showing 0 to 0 of 0 entries`}</div>
              )
            ) : (
              <div className="ml-3 mr-1">{`Showing 0 to 0 of 0 entries`}</div>
            )}
            <div className="ml-3 mr-1">Go to page:</div>
            <div className="width-70 mx-2">
              <input
                className="form-control"
                type="number"
                style={{ width: 70 }}
                defaultValue={page}
                onChange={(e) => {
                  const page = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(page);
                }}
              />
            </div>
            <div>
              <select
                className="form-select"
                value={per_page}
                onChange={(e) => {
                  set_per_page(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50, 60].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div style={buttonContainerStyle}>
            <button disabled={existPendingMigraciones == false}
              className="panel-iconbutton-clear"
              onClick={() => {
                refreshButtonAction();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                width="20px"
                height="20px"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z" />
              </svg>
              <strong>Refresh</strong>
            </button>
            <button
              className="panel-iconbutton-search" disabled={existProcessRequest == false}
              onClick={() => {
                submitButtonAction();
              }}
            >
              <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 4.75L5.99999 13.4583L2.66666 9.5" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <strong>Submit</strong>
            </button>
          </div>
        </PanelBody>
      </Panel>
      {ModalGeneralParam.modalGeneralShow && (
        <ModalGeneral
          isModalOpen={ModalGeneralParam.modalGeneralShow}
          onlyModal={false}
          type={ModalGeneralParam.type!}
          title={ModalGeneralParam.title}
          desc={ModalGeneralParam.sub_title}
          confirmBtnText="Ok"
          onClose={() => {
            showModalGeneralParam(false);
          }
          }
          onConfirm={() => {
            showModalGeneralParam(false);
          }}
        ></ModalGeneral>
      )}
      {showProcessing && (
        <div className="backgroundProcessing">
          <div className="bpWaiting">Processing...</div>
        </div>
      )}
      {showProcessingRefresh && (
        <div className="backgroundProcessing">
          <div className="bpWaiting">Processing...</div>
        </div>
      )}
    </div>
  );
};

export default ManualCEQuery;