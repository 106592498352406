import React, { useState, useEffect } from 'react';
import HttpCliente from "../../services/HttpCliente";
import axios from "axios";
import StatusResetPopup from "../../components/popups/status-reset-popup";
import RetryCallbackPopup from "../../components/popups/retry-callback-popup";
import { useTable, useSortBy, usePagination } from "react-table";
import fileDownload from "js-file-download";
import moment from "moment";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import makeData from "./make-data";
import "./style.scss";
import DatePicker from "react-datepicker";
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import { AppSettings } from "../../config/app-settings";
import { useNavigate, NavLink, Link  } from "react-router-dom";
import ReactLoading from 'react-loading';
import ModalGeneral from "../modal/modalGeneral";

const MainTable = ({ pageName, pageTitle, resetMenu }) => {

  const [typeDocumentDropdown, setTypeDocumentDropdown] = useState([]);
  
  useEffect(() => {
    // Load dropdown data only once when the component mounts
    setTypeDocumentDropdown([
      { id: "DNI", name: "DNI" },
      { id: "CE", name: "CE" }
    ]);
  }, []);


  const PageSettingsContext = React.useContext(AppSettings);
  ////////////////////////////////////////////////TABLE COLUMN HEADERS SET UP////////////////////////////////////////////////////////////////////////////////////////
  let memoSetter;

    memoSetter = [
      {
        Header: "Options",
        accessor: "options",
        sortable: false,
      },
      {
        Header: "KYC #",
        accessor: "id",
        sortable: true,
      },
      {
        Header: "Date",
        accessor: "request_date",
        sortable: true,
      },
      {
        Header: "Reference #",
        accessor: "request_reference",
        sortable: true,
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        sortable: true,
      },
      {
        Header: "Type",
        accessor: "customer_id_number",
        sortable: true,
      },
      {
        Header: "Doc. Num.",
        accessor: "customer_id_type",
        sortable: true,
      },
      {
        Header: "Source",
        accessor: "type_source",
        sortable: true,
      },
      {
        Header: "KYC Status",
        accessor: "kyc_status_name",
        sortable: true,
      },
      {
        Header: "Client",
        accessor: "partner_name",
        sortable: true,
      },
    ];

  const columns = React.useMemo(() => memoSetter, []);
  const data = React.useMemo(() => makeData(4000), []);
  let titlePage = pageTitle;
  const { getTableProps, headerGroups, prepareRow, canNextPage } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination
  );
  ////////////////////////////////////////////////STATE VARIABLE DECLARATIONS////////////////////////////////////////////////////////////////////////////////////////
  let [kycDropdown, setKycDropdown] = React.useState([]);
  const [statusResetPopup, toggleStatusResetPopup] = React.useState(false);
  const [retryCallbackPopup, toggleRetryCallbackPopup] = React.useState(false);
  let [partnersDropdown, setPartnersDropdown] = React.useState([]);
  let [statusDropdown, setStatusDropdown] = React.useState([]);
  let [typeSourceDropdown,setTypeSourceDropdown]= React.useState([]);

  let [requestData, setRequestData] = React.useState([]);  
  //Table request query parameters
  const [per_page, set_per_page] = React.useState(50);
  const [page, set_page] = React.useState(0);
  const [sort, set_sort] = React.useState("");
  const [triggerSort, setTriggerSort] = React.useState(false);
  const [direction, set_direction] = React.useState("");
  ////////////////////////////////////////////////////////////// SELECTION FIELDS ///////////////////////////////////////////////////////
  const [status_dropdown, set_status_dropdown] = React.useState(
    PageSettingsContext.status_dropdown
  );
  const [status, set_status] = React.useState(PageSettingsContext.status);
  const [id, set_id] = React.useState(PageSettingsContext.id);
  const [date_from, set_date_from] = React.useState(
    PageSettingsContext.date_from
  );
  const [date_to, set_date_to] = React.useState(PageSettingsContext.date_to);
  const [customer_name, set_customer_name] = React.useState(PageSettingsContext.customer_name);
  const [kyc_status, set_kyc_status] = React.useState(
    PageSettingsContext.kyc_status
  );

  const [type_document, set_type_document] = React.useState(
    PageSettingsContext.type_document
  );
  const [type_source, set_type_source] = React.useState(
    PageSettingsContext.type_source
  );
  const [partner_id, set_partner_id] = React.useState(
    PageSettingsContext.partner_id
  );
  
  const [reference, set_reference] = React.useState(    
    PageSettingsContext.reference
  );

  const [document_number, set_document_number] = React.useState(    
    PageSettingsContext.document_number
  );

  const [predifinedNote, set_PredifinedNote] = React.useState(    
    PageSettingsContext.predifinedNote
  );

  
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [requestBoard, setRequestBoard] = React.useState(false);
  const [sweetAlertActive, setSweetAlertActive] = React.useState(false);
  const [sweetAlertMessage, setSweetAlertMessage] = React.useState("Default");
  const [tableHeaderClassname, setTableHeaderClassname] = React.useState("");

  const [selectedResetRecords, setSelectedResetRecords] = React.useState([]);
  const [onlytoPending, set_onlytoPending] = React.useState();
  const [selectedErrorRecords, setSelectedErrorRecords] = React.useState([]);
  const [selectedPendingRecords, setSelectedPendingRecords] = React.useState(
    []
  );
  const [triggerRender, setTriggerRender] = React.useState(false);
  const statusResetRef = React.useRef();
  const [sweetAlertSuccessBool, setSweetAlertSuccessBool] = React.useState(
    false
  );
  const [sweetAlertSuccess, setSweetAlertSuccess] = React.useState(false);

  ////////////////////////////////////////////////RESET PAGE STATE////////////////////////////////////////////////////////////////////////////////////////

  React.useState(() => {
    setRequestBoard(resetMenu);
  });
  ////////////////////////////////////////////////SWEET ALERTS TOGGLE HANDLER////////////////////////////////////////////////////////////////////////////////////////

  const toggleSweetAlert = (message, success, type) => {
    setSweetAlertSuccessBool(success);
    setSweetAlertSuccess(type);
    setSweetAlertActive(!sweetAlertActive);
    setSweetAlertMessage(message);
  };

  React.useEffect(() => {
    //console.log(onlytoPending);
  }, [onlytoPending]);

  ////////////////////////////////////////////////CHECK FOR PAGE NUMBER EXCEEDING TOTAL////////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    if (typeof requestData !== "undefined") {
      if (page > requestData.last_page - 1) {
        set_page(requestData.last_page - 1);
      }
      if (requestData.last_page === 0) {
        set_page(0);
      }
    }
  });
  ////////////////////////////////////////////////PAGINATION HANDLERS////////////////////////////////////////////////////////////////////////////////////////

  const gotoPage = (value) => {
    set_page(value);
  };
  const nextPage = () => {
    if (typeof requestData !== "undefined") {
      if (page < requestData.last_page - 1) {
        set_page(page + 1);
      }
    }
  };
  const previousPage = () => {
    if (page > 0) set_page(page - 1);
  };


  ////////////////////////////////////////////////GET API CALL FUNCTION////////////////////////////////////////////////////////////////////////////////////////
  const setGetRequestData = (url, storage) => {
    HttpCliente.get(url)
      .then((response) => {
        storage(response.data);
        // console.log(response)
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };
  const setGetRequestDataNote = (url, storage) => {
    HttpCliente.get(url)
      .then((response) => {
        storage(response.data.data);
        // console.log(response)
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };
  ////////////////////////////////////////////////USE EFFECT FOR API CALLS SETUP////////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    setGetRequestData("/partner", setPartnersDropdown);
    //setGetRequestData("/bank ", setBankDropdown);
    setGetRequestData("/status/request ", setStatusDropdown);
    setGetRequestData("/status/kyc ", setKycDropdown);
    setGetRequestData("/typesource ", setTypeSourceDropdown);
    
    initiateButtonSearch();
  }, [page, per_page, triggerSort, requestBoard, triggerRender]);

  ////////////////////////////////////////////////REQUEST URL CREATOR FUNCTION////////////////////////////////////////////////////////////////////////////////////////
  const createRequestUrl = (startAddress) => {
    let getRequestUrl;
    let defaultValues;
    if (date_to === "" && date_from === "") {
      defaultValues = `per_page=${per_page}&page=${
        page + 1
      }&sort=${sort}&direction=${direction}&id=${id}&customer_name=${customer_name}&partner_id=${partner_id}&type_source=${type_source}&id_number=${document_number}&id_type=${type_document}&notes=${predifinedNote}`;
    } else if (date_to === "") {
      defaultValues = `per_page=${per_page}&page=${
        page + 1
      }&sort=${sort}&direction=${direction}&id=${id}&date_from=${moment(
        date_from
      )
        .add(0, "h")
        .add(0, "m")
        .add(0, "s")
        .unix()}&customer_name=${customer_name}&partner_id=${partner_id}&type_source=${type_source}&id_number=${document_number}&id_type=${type_document}&notes=${predifinedNote}`;
    } else if (date_from === "") {
      defaultValues = `per_page=${per_page}&page=${
        page + 1
      }&sort=${sort}&direction=${direction}&id=${id}&date_to=${moment(
        date_to
      )
        .add(0, "h")
        .add(0, "m")
        .add(0, "s")
        .unix()}&customer_name=${customer_name}&partner_id=${partner_id}&type_source=${type_source}&id_number=${document_number}&id_type=${type_document}&notes=${predifinedNote}`;
    } else {
      defaultValues = `per_page=${per_page}&page=${
        page + 1
      }&sort=${sort}&direction=${direction}&id=${id}&date_from=${moment(
        date_from
      )
        .add(0, "h")
        .add(0, "m")
        .add(0, "s")
        .unix()}&date_to=${moment(date_to)
        .add(23, "h")
        .add(59, "m")
        .add(59, "s")
        .unix()}&customer_name=${customer_name}&partner_id=${partner_id}&type_source=${type_source}&id_number=${document_number}&id_type=${type_document}&notes=${predifinedNote}`;
    }

      getRequestUrl = `${startAddress}${defaultValues}&kyc_status=${kyc_status}&status=${[
        5,
      ]}&reference=${reference}`;
    
    return getRequestUrl;
  };
  ////////////////////////////////////////////////FUNCTION USED TO CALL API WHEN FIELDS ARE SET UP////////////////////////////////////////////////////////////////////////////////////////
  const initiateButtonSearch = () => {
    setSelectedResetRecords([]);
    setSelectedErrorRecords([]);
    setSelectedPendingRecords([]);
    setRequestData(undefined);
    HttpCliente.get(createRequestUrl("/requestsource/?"))
      .then((response) => {
        //console.log(response.data)
        setRequestData(response.data);
      })
      .catch((error) => {
        console.log("Error:", error.response);
        setRequestData({ data: undefined });
      });
  };

  ////////////////////////////////////////////////BUTTON HANDLERS////////////////////////////////////////////////////////////////////////////////////////
  const clearButtonHandler = () => {
    setSelectedResetRecords([]);
    setSelectedErrorRecords([]);
    setSelectedPendingRecords([]);
    set_status([]);
    set_status_dropdown([]);
    set_per_page(50);
    set_page(0);
    set_sort("");
    setTriggerSort(false);
    set_direction("");
    set_id("");
    set_date_from("");
    set_date_to("");
    set_customer_name("");
    set_kyc_status("");
    set_type_source("");
    set_partner_id("");
    set_reference([]);
    set_document_number("");
    set_type_document("");
    set_PredifinedNote([]);

    PageSettingsContext.set_status([]);
    PageSettingsContext.set_status_dropdown([]);
    PageSettingsContext.set_id("");
    PageSettingsContext.set_date_from("");
    PageSettingsContext.set_date_to("");
    PageSettingsContext.set_customer_name("");
    PageSettingsContext.set_kyc_status("");
    PageSettingsContext.set_type_source("");
    PageSettingsContext.set_partner_id("");
    PageSettingsContext.set_document_number("");
    PageSettingsContext.set_reference([]);
    PageSettingsContext.set_type_document("");
    PageSettingsContext.set_PredifinedNote([]);

    setTriggerRender(!triggerRender);
  };
  const exportButtonAction = () => {
    const hideClass = "hide";
    document.getElementById("prgLoading").className = "show";
    axios({
      url: createRequestUrl(
        `/requestsource/export/?&time_zone_off_set=${-(
          new Date().getTimezoneOffset() / 60
        )}&`
      ),
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        fileDownload(response.data, "sourcerequests.xlsx");
        toggleSweetAlert(`Download Started!`, true, "success");
        document.getElementById("prgLoading").className = hideClass;
      })
      .catch((error) => {
        document.getElementById("prgLoading").className = hideClass;
        toggleSweetAlert(
          `Error: ${
            error.response.data.errors
              ? error.response.data.errors.message
              : "Failed"
          }`,
          false,
          "danger"
        );
      });
  };

  //itd 256 begin
  const navigate = useNavigate();
  //itd 256 end
  const processQueueHandler = () => {
    HttpCliente.get(`/request/0`)
      .then((response) => {
        if (response.status === 404) {
          toggleSweetAlert(
            "There is no pending KYC requests to process",
            false,
            "danger"
          );
        } else {
          if (typeof window !== `undefined`) {
            localStorage.setItem("typeEdit", "processQueue");
            navigate(`${window.location.pathname}/edit/${response.data.id}`);
          }
        }
      })
      .catch((error) => {
        toggleSweetAlert(`Error: ${error.response}`, false, "danger");
      });
  };
  ////////////////////////////////////////////////REQUEST READ/WRITE BOARD TOGGLER////////////////////////////////////////////////////////////////////////////////////////
  const toggleRequestBoard = () => {
    PageSettingsContext.handleCustomPopupView(!PageSettingsContext.customPopup);
    setRequestBoard(!requestBoard);
  };
  ////////////////////////////////////////////////LOADING ANIMATION FUNCTION////////////////////////////////////////////////////////////////////////////////////////
  const loadingAnimation = () => {
    return (
      <tr>
        <td colSpan={10}>
          <div style={{ padding: "15vh 0% 15vh 38vw" }}>
            <ReactLoading type="spin" color="#00aeab" height={65} width={65} />
          </div>
        </td>
      </tr>
    );
  };
  ////////////////////////////////////////////////TABLE FIELD STYLE SELECTION////////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
      setTableHeaderClassname("mb-3 col-md-3");
  }, []);
  ////////////////////////////////////////////////RETURN OF COMPONENT////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div id="animate" key={"MainTable"}>
      {statusResetPopup ? (
        <ModalGeneral
          isModalOpen={statusResetPopup}
          onlyModal={true}
        >
        <StatusResetPopup
            resetList={selectedResetRecords}
            setResetList={setSelectedResetRecords}
            toggle={toggleStatusResetPopup}
            toggleSweetAlert={toggleSweetAlert}
            triggerRender={triggerRender}
            setTriggerRender={setTriggerRender}
			onlytoPending={onlytoPending}
          />
      </ModalGeneral>
      ) : null}
      {retryCallbackPopup ? (
        <ModalGeneral
          isModalOpen={retryCallbackPopup}
          onlyModal={true}
        >
          <RetryCallbackPopup
            resetList={selectedResetRecords}
            setResetList={setSelectedResetRecords}
            toggle={toggleRetryCallbackPopup}
            toggleSweetAlert={toggleSweetAlert}
            triggerRender={triggerRender}
            setTriggerRender={setTriggerRender}
          />
        </ModalGeneral>
      ) : null}
      {sweetAlertActive ? (
        <ModalGeneral
          isModalOpen={sweetAlertActive}
          onlyModal={false}
          confirmBtnBsStyle={`${
            sweetAlertSuccessBool ? "success" : "error"
          }`}
          type={`${sweetAlertSuccess}`}
          onConfirm={() => {
            setSweetAlertActive(false);
          }}
          onCancel={() => {
            setSweetAlertActive(false);
          }}
          onClose={() => {
            setSweetAlertActive(false);
          }}
          title={sweetAlertMessage}
        ></ModalGeneral>
      ) : null}
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="#">{titlePage}</Link>
        </li>
        <li className="breadcrumb-item active">Search Data</li>
      </ol>
      <h1 className="page-header">{titlePage}</h1>
      <Panel>
        <PanelHeader noButton={true}>Filter by</PanelHeader>
        <PanelBody>
          <form action="" method="POST">
            <fieldset>
              <div className="row">
                <div className={tableHeaderClassname}>
                  <label className="form-label">
                    <strong>KYC #</strong>
                  </label>
                  <input
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        initiateButtonSearch();
                      }
                    }}
                    type="text"
                    className="form-control"
                    id="InputId"
                    placeholder="Enter Id"
                    value={id}
                    onChange={(e) => {
                      set_id(e.target.value);
                      PageSettingsContext.set_id(e.target.value);
                    }}
                  />
                </div>
                <div className={tableHeaderClassname}>
                  <label className="form-label">
                    <strong>Date From</strong>
                  </label>
                      <DatePicker
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            initiateButtonSearch();
                          }
                        }}
                        className="form-control"
                        id="InputDateFrom"
                        placeholderText={"Please select a date"}
                        max="1970-01-01"
                        min={date_to}
                        selected={date_from}
                        dateFormat="dd/MM/yyyy"
                        onChange={(e) => {
                          set_date_from(e);
                          PageSettingsContext.set_date_from(e);
                        }}
                        autoComplete="off"
                      />
                </div>
                <div className={tableHeaderClassname}>
                  <label className="form-label">
                    <strong>To</strong>
                  </label>
                      <DatePicker
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            initiateButtonSearch();
                          }
                        }}
                        className="form-control"
                        id="InputDateTo"
                        placeholderText={"Please select a date"}
                        min={date_from}
                        max="2030-12-31"
                        dateFormat="dd/MM/yyyy"
                        selected={date_to}
                        onChange={(e) => {
                          set_date_to(e);
                          PageSettingsContext.set_date_to(e);
                        }}
                        autoComplete="off"
                      />
                </div>

                <div className="mb-3 col-md-3">
                  <label className="form-label">
                    <strong>Client</strong>
                  </label>
                  <br />
                  <select
                    className="form-select"
                    name="client-select"
                    id="InputClient"
                    value={partner_id}
                    onChange={(e) => {
                      set_partner_id(e.target.value);
                      PageSettingsContext.set_partner_id(e.target.value);
                    }}
                  >
                    <option value="null" disabled hidden>
                      Select Client
                    </option>
                    <option value={""} key={"all"}>
                      [ALL]
                    </option>
                    {partnersDropdown.map((value, index) => (
                      <option
                        value={value.partnerID}
                        key={value.partnerName}
                      >
                        {value.partnerName}
                      </option>
                    ))}
                  </select>
                </div>
                
              </div>
              <div className="row">
                <div className="mb-3 col-md-3">
                  <label className="form-label">
                    <strong>Customer Name</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="InputCustomerName"
                    placeholder="Customer Name"
                    value={customer_name}
                    onChange={(e) => {
                      set_customer_name(e.target.value);
                      PageSettingsContext.set_customer_name(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-3 col-md-3">
                    <label className="form-label">
                      <strong>Type</strong>
                    </label>
                    <br />
                    <select
                      className="form-select"
                      name="client-select"
                      id="InputType"
                      value={type_document}
                      onChange={(e) => {
                        set_type_document(e.target.value);
                        PageSettingsContext.set_type_document(e.target.value);
                      }}
                    >
                      <option value="null" disabled hidden>
                        Select Type Document
                      </option>
                      <option value={""} key={"all"}>
                        [ALL]
                      </option>
                      {typeDocumentDropdown.map((value, index) => (
                        <option
                          value={value.id}
                          key={value.name}
                        >
                          {value.name}
                        </option>
                      ))}
                    </select>
                </div>

                <div className="mb-3 col-md-3">
                  <label className="form-label">
                    <strong>Doc. No.</strong>
                  </label>
                  <input
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        initiateButtonSearch();
                      }
                    }}
                    type="text"
                    className="form-control"
                    id="InputDocumentNumber"
                    placeholder="Document Number"
                    value={document_number}
                    onChange={(e) => {
                      set_document_number(e.target.value);
                      PageSettingsContext.set_document_number(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-3 col-md-3">
                    <label className="form-label">
                      <strong>Reference #</strong>
                    </label>
                    <br />
                    <input
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        initiateButtonSearch();
                      }
                    }}
                    type="text"
                    className="form-control"
                    id="InputReference"
                    placeholder="Reference #"
                    value={reference}
                    onChange={(e) => {
                      set_reference(e.target.value);
                      PageSettingsContext.set_reference(e.target.value);
                    }}
                  />
                  </div>
              </div>

              <div className="row">

                  <div className="mb-3 col-md-3">
                  <label className="form-label">
                    <strong>Source</strong>
                  </label>
                  <br />
                  <select
                    className="form-select"
                    name="client-select"
                    id="InputTypeSource"
                    value={type_source}
                    onChange={(e) => {
                      set_type_source(e.target.value);
                      PageSettingsContext.set_type_source(e.target.value);
                    }}
                  >
                    <option value="null" disabled hidden>
                      Select Type Source
                    </option>
                    <option value={""} key={"all"}>
                      [ALL]
                    </option>

                    {typeSourceDropdown.map((value, index) => (
                      <option value={value.typeSourceId} key={value.typeSourceName}>
                        {value.typeSourceName}
                      </option>
                    ))}
                  </select>
                  </div>


                  <div className="mb-3 col-md-3">
                    <label className="form-label">
                      <strong>KYC Status</strong>
                    </label>
                    <br />
                    <select
                      className="form-select"
                      name="client-select"
                      id="InputKYCStatus"
                      value={kyc_status}
                      onChange={(e) => {
                        set_kyc_status(e.target.value);
                        PageSettingsContext.set_kyc_status(e.target.value);
                      }}
                    >
                      <option value="null" disabled hidden>
                        Select Status
                      </option>
                      <option value={""} key={"all"}>
                        [ALL]
                      </option>
                      {kycDropdown.map((value, index) => (
                        <option
                          value={value.kycStatusId}
                          key={value.kycStatusId}
                        >
                          {value.kycStatusName}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <div className="mb-3 col-md-3">
                    <label className="form-label">
                      <strong>Notes</strong>
                    </label>
                    <br />
                    <input
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        initiateButtonSearch();
                      }
                    }}
                    type="text"
                    className="form-control"
                    id="InputNotes"
                    placeholder="Notes"
                    value={predifinedNote}
                    onChange={(e) => {
                      set_PredifinedNote(e.target.value);
                      PageSettingsContext.set_PredifinedNote(e.target.value);
                    }}
                  />
                </div>


              </div>
            </fieldset>
          </form>
          <div className="panel-button-row mb-7px">
            <div>
            </div>
            <div>
              <button
                className="panel-iconbutton-clear"
                onClick={() => {
                  clearButtonHandler();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  width="20px"
                  height="20px"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z" />
                </svg>
                <strong>Clear</strong>
              </button>
              <button
                className="panel-iconbutton"
                onClick={() => {
                  exportButtonAction();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enableBackground="new 0 0 24 24"
                  viewBox="0 0 24 24"
                  fill="white"
                  width="20px"
                  height="20px"
                >
                  <g>
                    <path d="M0,0h24v24H0V0z" fill="none" />
                  </g>
                  <g>
                    <g>
                      <path d="M18,2h-8L4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M18,4v16H6V8.83L10.83,4H18z" />
                      <path d="M16,13l-4,4l-4-4l1.41-1.41L11,13.17V9.02L13,9v4.17l1.59-1.59L16,13z" />
                    </g>
                  </g>
                </svg>
                <strong>Export&nbsp;</strong>
                <div id="prgLoading" className="hide"><ReactLoading type="spin" color="white" height={15} width={15} /></div>
              </button>
              <button
                className="panel-iconbutton-search"
                onClick={initiateButtonSearch}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  width="20px"
                  height="20px"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                </svg>
                <strong>Search</strong>
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr key={"tr"} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        key={"th"}
                        className="width-100"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div
                          className="d-flex"
                          style={{ minWidth: "60px" }}
                          onClick={() => {
                            if (column.isSorted === true) {
                              if (column.isSortedDesc === true) {
                                set_direction("");
                                set_sort("");
                              } else {
                                set_direction("desc");
                                set_sort(column.id);
                              }
                            } else {
                              set_direction("asc");
                              set_sort(column.id);
                            }
                            setTriggerSort(!triggerSort);
                          }}
                        >
                          <span>{column.render("Header")}</span>
                          <span className="ml-auto">
                            {column.sortable ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-sort-down fa-fw f-s-14 text-blue"></i>
                                ) : (
                                  <i className="fa fa-sort-up fa-fw f-s-14 text-blue"></i>
                                )
                              ) : (
                                <i className="fa fa-sort fa-fw f-s-14 opacity-3"></i>
                              )
                            ) : null}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {typeof requestData !== "undefined"
                  ? typeof requestData.data !== "undefined"
                    ? requestData.data.map((line, i) => {
                        let row;
                        row = [
                          line.id,
                          moment
                            .unix(line.request_date)
                            .format("DD/MM/YYYY"),
                          line.request_reference,
                          line.customer_name,
                          line.customer_id_type,
                          line.customer_id_number,
                          line.type_source,
                          line.kyc_status_name,
                          line.partner_name,
                        ];
                        prepareRow(row);
                        return (
                          <tr
                            key={line.id}
                            className={
                              i % 2 !== 0
                                ? "table-data"
                                : "table-data-alter"
                            }
                          >
                            <td key={"search"}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <NavLink to={`${window.location.pathname}/view/${line.id}`}>
                                  <button
                                    className="form-button-search"
                                    onClick={() => {
                                      //searchIconHandler(line);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="-3 -2 30 30"
                                      fill="white"
                                      width="18px"
                                      height="18px"
                                    >
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                    </svg>
                                  </button>
                                </NavLink>
                              </div>
                            </td>
                            {row.cells.map((cell, index) => {
                              if (typeof cell.row[index] !== `undefined`) {
                                return (
                                  <td
                                    key={index}
                                    >
                                    {cell.row[index]}
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        );
                      })
                    : null
                  : loadingAnimation()}
              </tbody>
            </table>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <ul className="pagination mb-0">
              <li className="page-item">
                <button className="page-link" onClick={() => gotoPage(0)}>
                  <i className="fa fa-angle-double-left"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => previousPage()}
                >
                  <i className="fa fa-angle-left"></i>
                </button>
              </li>
              <li className="page-item d-flex align-items-center px-2">
                <div>
                  Page{" "}
                  {typeof requestData !== "undefined" ? (
                    typeof requestData.data !== "undefined" ? (
                      <strong>
                        {page + 1} of {requestData.last_page}
                      </strong>
                    ) : (
                      <strong>{page + 1} of 1</strong>
                    )
                  ) : (
                    <strong>{page + 1} of 1</strong>
                  )}
                </div>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <i className="fa fa-angle-right"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link mx-2"
                  onClick={() => gotoPage(requestData.last_page - 1)}
                >
                  <i className="fa fa-angle-double-right"></i>
                </button>
              </li>
            </ul>
            {typeof requestData !== "undefined" ? (
              typeof requestData.data !== "undefined" &&
              requestData.data !== null &&
              requestData.data !== [] ? (
                <strong>
                  <div className="ml-3 mr-1 mx-2">{`Showing ${
                    requestData.per_page * requestData.current_page -
                    (requestData.per_page - 1)
                  } to ${
                    requestData.per_page * requestData.current_page
                  } of ${requestData.total_count} entries`}</div>
                </strong>
              ) : (
                <div className="ml-3 mr-1">{`Showing 0 to 0 of 0 entries`}</div>
              )
            ) : (
              <div className="ml-3 mr-1">{`Showing 0 to 0 of 0 entries`}</div>
            )}
            <div className="ml-3 mr-1">Go to page:</div>
            <div className="width-70 mx-2">
              <input
                className="form-control"
                type="number"
                style={{ width:70 }}
                defaultValue={page + 1}
                onChange={(e) => {
                  const page = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(page);
                }}
              />
            </div>
            <div>
              <select
                className="form-select"
                value={per_page}
                onChange={(e) => {
                  set_per_page(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50, 60].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </PanelBody>
      </Panel>
  </div>
  );
};

export default MainTable;
