import "./style.scss";
import React from "react";
import { Panel, PanelHeader, PanelBody } from "../panel/panel";
import HttpCliente from "../../services/HttpCliente";

const RetryCallbackPopup = (props) => {
  const {
    toggle,
    resetList,
    setResetList,
    toggleSweetAlert,
    triggerRender,
    setTriggerRender,
  } = props;

  const [commentsText, setCommentsText] = React.useState("");
  const sigData = {
    comment: commentsText,
    status_id: 4,
  };
  const retryCallbackFormProcessHandler = () => {
    if (commentsText === "") {
      toggleSweetAlert("Please complete all fields", false, "info");
    } else {
      resetList.map((item) => {
        HttpCliente.post(`/request/${item}/history`, sigData)
          .then((response) => {
            toggleSweetAlert(
              "The status was resetted successfully",
              true,
              "success"
            );
            setResetList([]);
            setTriggerRender(!triggerRender);
            toggle(false);
          })
          .catch((error) => {
            console.log(error);
            toggleSweetAlert(
              `Error: ${
                error.response.data.errors
                  ? error.response.data.errors.message
                  : "Failed"
              }`,
              false,
              "danger"
            );
          });
      });
    }
  };
  return (
    <div>
      <div id="animate" className="wrapper">
        {alert}
        <Panel>
          <PanelHeader noButton>Retry Callback</PanelHeader>
          <PanelBody>
            <div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label class="form-label mb-2" htmlFor="NewStatusInput">
                      New status for the selected KYC requests
                    </label>
                    <select
                      disabled
                      className="form-select"
                      name="status-input"
                      id="NewStatusInput"
                    >
                      <option value={4}>Sent</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label class="form-label mb-2" htmlFor="CommentsInput">Comments</label>
                    <textarea
                      className="form-control"
                      type="text"
                      name="comments"
                      id="CommentsInput"
                      onChange={(e) => {
                        setCommentsText(e.target.value);
                      }}
                    />
                  </div>
                </div>
              <div className="form-group col-md-12">
                
              </div>
              <div className="panel-button-row">
                <button
                  className="panel-iconbutton-close"
                  onClick={() => toggle(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="18px"
                    height="18px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                  </svg>
                  <strong>Close</strong>
                </button>
                <button
                  className="panel-button"
                  onClick={() => retryCallbackFormProcessHandler()}
                >
                  <strong>Submit</strong>
                </button>
              </div>
            </div>
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default RetryCallbackPopup;
