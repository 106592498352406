import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';

import AppRoute from "../../config/app-route";
import Login from '../../pages/security/login';
import PageLoad from '../../pages/security/page-load';

class Content extends React.Component<any> {
	static contextType = AppSettings;
  context!: React.ContextType<typeof AppSettings>;

	constructor(props: any) {
    super(props);
  }

  render() {
    const userType = this.context.userDataLogin.userData.user_tipo_id;
    return (
      <>
        <AppSettings.Consumer>
          {
            ({appContentClass}) => (
              <div className={'app-content '+ appContentClass}>
                <Routes>
                  {
                    userType !== '' ? 
                      AppRoute(this.context.userDataLogin.userData.user_tipo_id).map((route, index) => (
                        <Route
                          key={index}
                          path={route.path}
                          element={route.element}
                        />
                      ))
                      : 
                      <>
                      <Route path={`${process.env.REACT_APP_Sub_Domain}`} element={<Login />} />
                      <Route path={`${process.env.REACT_APP_Sub_Domain}/Login`} element={<Login />} />
                      <Route path='*' element={<PageLoad />} />
                      </>
                  }
                </Routes>
              </div>
            )
          }
        </AppSettings.Consumer> 
      </>
		)
	}
}

export default Content;
