import React, { useEffect } from 'react';
import { AppSettings } from '../../config/app-settings.js';
import Duo from '../../components/duo-auth/duo.js';
import { loginDuo } from '../../actions/AuthAction';
import { storageInteractor } from "../../utils/storageInteractor";
import { useNavigate } from 'react-router-dom';

const objStorageInteractor = new storageInteractor();

function DuoAuth() {
	const PageSettingsContext = React.useContext(AppSettings);
	const navigate = useNavigate();

	useEffect(() => {
		if (!PageSettingsContext.userDataLogin.authenticated) {
			navigate(`${process.env.REACT_APP_Sub_Domain}/login`);
			return;
		}

    PageSettingsContext.handleSetAppSidebarNone(true);

    return() => {
      PageSettingsContext.handleSetAppSidebarNone(false);
    }
  }, []);

	const handle2FAComplete = (sigResponse: string) => {
		loginDuo({sig_response: sigResponse}).then((response: any) => {
			if (response.status === 401)
			{
				PageSettingsContext.handleSetUserDataLogin({data: {}}, true, true);
				objStorageInteractor.logOut_localStore();
				navigate(`${process.env.REACT_APP_Sub_Domain}/login`);
			}

			if (response.status === 200){
				if (response.data.is_sign_valid){
					objStorageInteractor.setTokenSecurity(response.data.token);
					objStorageInteractor.removeTokenSecurityTmp();
					PageSettingsContext.handleSetUserDataLogin({data: PageSettingsContext.userDataLogin.userData}, true, true);
					navigate(`${process.env.REACT_APP_Url_Home}`);
				}
			}
		});
	}

	return (
		<div className="row">
			<div className="col-xs-12 center-block text-center">
				{(PageSettingsContext.userDataLogin.authenticated) ? 
					<Duo
							host={PageSettingsContext.userDataLogin.userData.data_host}
							sigRequest={PageSettingsContext.userDataLogin.userData.data_sign_request}
							sigResponseCallback={handle2FAComplete}
						/>
				: <></>}
			</div>
		</div>
	)
}

export default DuoAuth;