const USER_TOKEN = 'token_security';
const USER_TOKEN_TMP = 'token_security_tmp';
const USER_COUNTDOWN_DATE = 'countdown_date';

export class storageInteractor {
  init() { }

  logOut_localStore(forceReload?: boolean): void {
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(USER_TOKEN_TMP);
    localStorage.removeItem(USER_COUNTDOWN_DATE);
    if (forceReload) window.location.reload();
  }

  getTokenSecurity(): string | null {
    return localStorage.getItem(USER_TOKEN);
  }

  setTokenSecurity(tokenValue: string): void {
    window.localStorage.setItem(USER_TOKEN, tokenValue);
  }

  getTokenSecurityTmp(): string | null {
    return localStorage.getItem(USER_TOKEN_TMP);
  }

  setTokenSecurityTmp(tokenValue: string): void {
    window.localStorage.setItem(USER_TOKEN_TMP, tokenValue);
  }

  removeTokenSecurityTmp(): void {
    localStorage.removeItem(USER_TOKEN_TMP);
  }

  getCountdownDateValue(): string | null {
    return localStorage.getItem(USER_COUNTDOWN_DATE);
  }

  setCountdownDateValue(value_time: string ): void {
    window.localStorage.setItem(USER_COUNTDOWN_DATE, value_time);
  }

  removeCountdownDateValue(): void {
    window.localStorage.removeItem(USER_COUNTDOWN_DATE);
  }
}

export default new storageInteractor();
