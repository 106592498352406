import React from 'react';
import { Link } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { slideToggle } from './../../composables/slideToggle.js';

class SidebarProfile extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			profileActive: 0
		};
		this.handleProfileExpand = this.handleProfileExpand.bind(this);
	}

	handleProfileExpand(e) {
		e.preventDefault();
		
		var targetSidebar = document.querySelector('.app-sidebar:not(.app-sidebar-end)');
		var targetMenu = e.target.closest('.menu-profile');
		var targetProfile = document.querySelector('#appSidebarProfileMenu');
		var expandTime = (targetSidebar && targetSidebar.getAttribute('data-disable-slide-animation')) ? 0 : 250;
	
		if (targetProfile) {
			if (targetProfile.style.display === 'block') {
				targetMenu.classList.remove('active');
			} else {
				targetMenu.classList.add('active');
			}
			slideToggle(targetProfile, expandTime);
			targetProfile.classList.toggle('expand');
		}
	}
  
	render() {
		return (
			<AppSettings.Consumer>
				{({appSidebarMinify, userDataLogin}) => (
					<div className="menu">
						<div className="menu-profile">
								<div className="menu-profile-cover with-shadow"></div>
								<div className="menu-profile-image">
									<img src={`${process.env.REACT_APP_Sub_Domain}/assets/img/user/user-16.jpg`} alt="" />
								</div>
								<div className="menu-profile-info">
									<div className="d-flex align-items-center">
										<div className="flex-grow-1">
											{userDataLogin.userData.full_name}
										</div>
									</div>
									<small>{userDataLogin.userData.user_tipo_des}</small>
								</div>
						</div>
					</div>
				)}
			</AppSettings.Consumer>
		)
	}
}

export default SidebarProfile;