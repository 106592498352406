import axios, { AxiosError, AxiosResponse } from 'axios';
import { storageInteractor } from "../utils/storageInteractor";

const objStorageInteractor = new storageInteractor();
axios.defaults.baseURL = process.env.REACT_APP_KYC_API_ENDPOINT;

axios.interceptors.request.use(function (config) {
  let token_security: string | null = objStorageInteractor.getTokenSecurity();

  if (config.url?.toLocaleLowerCase() === "/auth/loginduo")
  {
    token_security = objStorageInteractor.getTokenSecurityTmp();
  }

  if(token_security){
    config.headers.Authorization = 'Bearer ' + token_security;
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response!.status === 401) {
      objStorageInteractor.logOut_localStore(true);
    }
    return Promise.reject(error);
  }
);

const requestGeneric = {
  get : (url: string) => axios.get(url),
  post: (url: string, body: any) => axios.post(url, body),
  put : (url: string, body: any) => axios.put(url,body),
  delete : (url: string) => axios.delete(url)
};

export default requestGeneric;