import Login from "../pages/security/login";
import DuoAuth from "../pages/security/duoAuth";
import Page404 from "../pages/security/page-404";

import RequestAllSearch from '../pages/request/all/search';
import RequestPendingSearch from "../pages/request/pending/search";
import RequestTrackingSearch from "../pages/request/tracking/search";
import RequestHistorySearch from '../pages/request/history/search';
import RequestCustomValidation from "../pages/request/customer-validation/manual-query";
import RequestCEValidation from "../pages/request/customer-ce-validation/manual-query";
import RequestBatchCustomValidation from "../pages/request/customer-batch-validation/manual-batch-process";

import RequestSourceHistorySearch from '../pages/requestsource/history/search';

import NoteList from "../pages/notes/list/note-list";
import NewNote from '../pages/notes/new/new-note';

import NewCustomerGreyList from '../pages/customers/greyList/new-grey-list';
import CustomerGreyList from '../pages/customers/greyList/grey-list';
import CustomerList from '../pages/customers/list/customer-list';
import NewCustomer from '../pages/customers/new/new-customer';

import RequestForm from '../components/popups/request-form';
import RequestSourceForm from '../components/popups/requestsource-form';

import { GetIsPageAuthorize, ListIDPage, PagesKYCUrl } from "../utils/autorizationHelper";
import { Navigate } from 'react-router-dom';
import storageInteractor from '../utils/storageInteractor';

type RouteObject = {
  path: string;
  element: JSX.Element;
	idPage?: string;
	title?: string;
}

const protectedRoutes: RouteObject[] = [
	{
		path: '*',
		element: <Page404 />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}`,
		element: <RequestPendingSearch />
	},
	{
		path: PagesKYCUrl.duoAuthentication,
		title: "Duo Authentication",
		element: <DuoAuth />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/all/search`,
		title: "Request All",
		element: <RequestAllSearch />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/pending/search`,
		title: "Request Pending",
		element: <RequestPendingSearch />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/tracking/search`,
		title: "Request Tracking",
		element: <RequestTrackingSearch />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/history/search`,
		title: "Request History",
		element: <RequestHistorySearch />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/requestsource/history/search`,
		title: "KYC Registry History",
		element: <RequestSourceHistorySearch />
	},

	{
		path: `${process.env.REACT_APP_Sub_Domain}/requestsource/history/search/view/:kyc_id`,
		element: <RequestSourceForm editMode='view' key={1} />
	},

	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/pending/search/edit/:kyc_id`,
		element: <RequestForm editMode='edit' key={1} />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/pending/search/view/:kyc_id`,
		element: <RequestForm editMode='view' key={2} />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/all/search/edit/:kyc_id`,
		element: <RequestForm editMode='edit' key={3} />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/all/search/view/:kyc_id`,
		element: <RequestForm editMode='view' key={4} />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/tracking/search/view/:kyc_id`,
		element: <RequestForm editMode='view' key={5} />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/history/search/view/:kyc_id`,
		element: <RequestForm editMode='view' key={6} />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/customer-validation/manual-query`,
		title: "Manual KYC",
		idPage: ListIDPage.ManualKYC,
		element: <RequestCustomValidation />,
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/customer-ce-validation/manual-query`,
		title: "Manual CE",
		idPage: ListIDPage.ManualCE,
		element: <RequestCEValidation />,
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/request/customer-batch-validation/manual-batch-process`,
		title: "Batch Process",
		idPage: ListIDPage.ManualBatchProcess,
		element: <RequestBatchCustomValidation />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/customers/greyList/grey-list`,
		title: "Grey List",
		element: <CustomerGreyList />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/customers/greyList/new-grey-list`,
		title: "Add to Grey List",
		element: <NewCustomerGreyList />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/customers/list/customer-list`,
		title: "Customer List",
		element: <CustomerList />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/customers/list/customer-list/:edit_mode/:kyc_id`,
		element: <NewCustomer key={1} />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/customers/new/new-customer`,
		idPage: ListIDPage.NewCustomer,
		element: <NewCustomer key={2} />,
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/notes/list/note-list`,
		title: "Note List",
		element: <NoteList />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/notes/list/note-list/:edit_mode/:kyc_id`,
		element: <NewNote key={1} />
	},
	{
		path: `${process.env.REACT_APP_Sub_Domain}/notes/new/new-note`,
		idPage: ListIDPage.NewNote,
		element: <NewNote  key={2} />
	}
];

// Define tus rutas públicas que no requieren autenticación
const publicRoutes: RouteObject[] = [
	{
		path: `${process.env.REACT_APP_Sub_Domain}/Login`,
		title: "Login",
		element: <Login key={1} />
	},
	{
		path: `*`,
		element: <Login key={2} />
	}
];

// Función para validar y envolver las rutas protegidas
const validateProtectedRoutes = (userType: number | undefined, routes: RouteObject[]): RouteObject[] => {
  return routes.map(route => ({
    ...route,
    element: isAuthenticated(route) 
			? userHasPermission(userType, route)
			: <Navigate to={`${process.env.REACT_APP_Sub_Domain}/Login`} replace />,
  }));
};

const userHasPermission = (userType: number | undefined, route: RouteObject): JSX.Element => {
	let userHasPermission = true;

	if (userType !== undefined && route.idPage !== undefined) {
		userHasPermission = GetIsPageAuthorize(route.idPage!, userType);
	}
	
	return userHasPermission ? route.element : <Navigate to={`${process.env.REACT_APP_Sub_Domain}/pageNotFound`} replace />;
}

const isAuthenticated = (route: RouteObject): boolean => {
  const token_security = storageInteractor.getTokenSecurity();
	let isAuthenticated = (token_security !== null);

	if (route.path === PagesKYCUrl.duoAuthentication) {
		const token_security_tmp = storageInteractor.getTokenSecurityTmp();
		isAuthenticated = (token_security_tmp !== null);
	}

	return isAuthenticated;
};

// Combina tus rutas públicas y protegidas
const AppRoute = (userType: number | undefined) : RouteObject[] => {
	return [
		...validateProtectedRoutes(userType, protectedRoutes),
		...publicRoutes,
	];
}

export default AppRoute;
