import React from 'react';
import { useNavigate  } from "react-router-dom";
import { AppSettings } from "../../../config/app-settings";

function DropdownProfile() {
	const PageSettingsContext = React.useContext(AppSettings);
	const navigate = useNavigate();

	const logOutSesionApp = () => {
    PageSettingsContext.handleLogOutApp();
		navigate(`${process.env.REACT_APP_Sub_Domain}/login`);
  }

	return (
		<div className="navbar-item navbar-user dropdown">
			<a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
				<img src={`${process.env.REACT_APP_Sub_Domain}/assets/img/user/user-16.jpg`} alt="" /> 
				<span>
				<span className="d-none d-md-inline">{PageSettingsContext.userDataLogin.userData.full_name}&nbsp;</span>
					<b className="caret"></b>
				</span>
			</a>
			<div className="dropdown-menu dropdown-menu-end me-1">
				<a href='#' onClick={logOutSesionApp} className="dropdown-item">Log Out</a>
			</div>
		</div>
	);
};

export default DropdownProfile;
