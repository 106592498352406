import HttpCliente from "../services/HttpCliente";
import axios from 'axios';

const instancia = axios.create();
instancia.defaults.baseURL = process.env.REACT_APP_KYC_API_ENDPOINT;

export const loginUser = (userData: any) => {

  return new Promise((resolve, eject) => {
    instancia.post("/Auth/Login", userData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const loginDuo = (sigData: any) => {
  return new Promise((resolve, eject) => {
    HttpCliente.post("/Auth/LoginDuo", sigData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const pingUser = () => {
  return new Promise((resolve, eject) => {
    HttpCliente.get("/Auth/Ping")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};