import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppSettings } from "../../config/app-settings";

function Page404() {
	const PageSettingsContext = React.useContext(AppSettings);
	const navigate = useNavigate();


	useEffect(() => {
    PageSettingsContext.handleSetAppSidebarNone(true);
    PageSettingsContext.handleSetAppHeaderNone(true);
    PageSettingsContext.handleSetAppContentClass('p-0');

    return() => {
      PageSettingsContext.handleSetAppSidebarNone(false);
      PageSettingsContext.handleSetAppHeaderNone(false);
      PageSettingsContext.handleSetAppContentClass('');
    }
  }, []);


	const redirectHome = () => {
		navigate(`${process.env.REACT_APP_Url_Home}`);
	
		PageSettingsContext.handleSetAppSidebarNone(false);
    PageSettingsContext.handleSetAppHeaderNone(false);
    PageSettingsContext.handleSetAppContentClass('');
	}

	return (
		<div className="error">
			<div className="error-code">404</div>
			<div className="error-content">
				<div className="error-message">We couldn't find it...</div>
				<div className="error-desc mb-4">
					The page you're looking for doesn't exist. <br />
					Perhaps, there pages will help find what you're looking for.
				</div>
				<div>
					<a href='#' onClick={redirectHome} className="btn btn-success px-3">Go Home</a>
				</div>
			</div>
		</div>
	)
}

export default Page404;