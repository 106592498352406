import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';

// bootstrap
import 'bootstrap';

// css
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <BrowserRouter >
  	<App />
  </BrowserRouter>
);