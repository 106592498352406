import { useState, useRef, useEffect, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel.jsx";
import HttpCliente from "../../../services/HttpCliente";
import ModalGeneral, { ModalGeneralType } from "../../../components/modal/modalGeneral";

interface State {
  query_type: number;
  priority_customer: boolean;
  priority_reniec: boolean;
  input_data: string;
}

const initState = {
  query_type: 1,
  priority_customer: true,
  priority_reniec: true,
  input_data: "",
};

//alert info
interface ModalGeneralParam {
  title?: string;
  sub_title?: string;
  type?: ModalGeneralType;
  modalGeneralShow?: boolean;
}
const initModalGeneralParam = {
  title: "",
  sub_title: "",
  type: undefined,
  modalGeneralShow: false,
};
//end alert info

const ManualQuery = () => {
  const [state, setState] = useState<State>(initState);
  const [ModalGeneralParam, setModalGeneralParam] = useState<ModalGeneralParam>(initModalGeneralParam);
  const [outputDataMQ, setOutputDataMQ] = useState([]);
  const [showProcessing, setShowProcessing] = useState(false);

  const txtInputData = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (txtInputData.current) txtInputData.current.focus();
  }, [txtInputData]);

  const handleInputChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLTextAreaElement>) => setState({ ...state, [name]: value });

  const handleInputCheckChange = ({
    target: { id, checked },
  }: ChangeEvent<HTMLInputElement>) => setState({ ...state, [id]: checked });

  const handleValidateData = () => {
    const inputData = state.input_data;

    //Validations
    if (!state.priority_customer && !state.priority_reniec) {
      showModalGeneralParam(true, "Priority is requerid.", "You need to select 'Customer List' or 'RENIEC'", "info");
      return;
    }
    if (inputData.trim() === ""){
      showModalGeneralParam(true, "Input Data is requerid.", "", "info");
      return;
    }
    //

    if (state.query_type === 1) { 
      const lstRow = inputData.split("\n");
      const arrayDataByDNI: any = [];
      let sentData = true;
      let nroRow = 1;

      lstRow.some(element => {
        let fiels = element.split(",");

        let continueLoop = true;
        if (fiels.length != 3) {
          continueLoop = false;
        } else {
          if (fiels[0].trim() === "" || fiels[1].trim() === "" || fiels[2].trim() === "") {
            continueLoop = false;
          }
        }

        if (!continueLoop) {
          fiels = element.split("\t");
          continueLoop = true;
        }

        if (fiels.length != 3) {
          continueLoop = false;
        } else {
          if (fiels[0].trim() === "" || fiels[1].trim() === "" || fiels[2].trim() === "") {
            continueLoop = false;
          }
        }

        if (!continueLoop) {
          showModalGeneralParam(true, "Some row doesn't have the correct format.",  `Row ${nroRow}: ${(element == "" ? "it is empty" : element)}`, "info");
          sentData = false;
          return true;
        }

        arrayDataByDNI.push({
          dni: fiels[0].trim(),
          customer_name: fiels[1].trim(),
          bank_account: fiels[2].trim()
        });

        nroRow += 1;
      });
      
      if (sentData) queryByDNI(arrayDataByDNI);
    }

    if (state.query_type === 2) { 
      const lstRow = inputData.split("\n");
      const arrayDataByPayout: Array<number> = [];
      let sentData = true;
      let nroRow = 1;

      lstRow.some(element => {
        let fiels = element.split(",");

        let continueLoop = true;
        if (fiels.length != 1) {
          continueLoop = false;
        } else {
          if (fiels[0].trim() === "" || !Number(fiels[0])) {
            continueLoop = false;
          }
        }

        if (!continueLoop) {
          fiels = element.split("\t");
          continueLoop = true;
        }

        if (!continueLoop) {
          showModalGeneralParam(true, "Some row doesn't have the correct format.",  `Row ${nroRow}: ${(element == "" ? "it is empty" : element)}`, "info");
          sentData = false;
          return true;
        }

        arrayDataByPayout.push(Number(fiels[0]));

        nroRow += 1;
      });
      
      if (sentData) queryByPayout(arrayDataByPayout);
    }
  };

  const queryByDNI = (arrayDataByDNI: any) => {
    const sigData = {
      query_kyc_customer: state.priority_customer ? "1" : "0",
      query_reniec: state.priority_reniec ? "1" : "0",
      data: arrayDataByDNI
    };

    setOutputDataMQ([]);
    setShowProcessing(true);
    HttpCliente.post(`/customer/validate`, sigData)
          .then((response) => {
            setOutputDataMQ(response.data.data);
            setShowProcessing(false);
          })
          .catch((error) => {
            setShowProcessing(false);
            console.log(error);
            showModalGeneralParam(true, "Failed", `Failed: ${
              error.response.data.errors ? error.response.data.errors.data : ""
            }`, "warning");
          });
  }

  const queryByPayout = (arrayDataByPayout: any) => {
    const sigData = {
      query_kyc_customer: state.priority_customer ? "1" : "0",
      query_reniec: state.priority_reniec ? "1" : "0",
      data: arrayDataByPayout
    };

    setOutputDataMQ([]);
    setShowProcessing(true);
    HttpCliente.post(`/payout/validate`, sigData)
          .then((response) => {
            setOutputDataMQ(response.data.data);
            setShowProcessing(false);
          })
          .catch((error) => {
            setShowProcessing(false);
            console.log(error);
            showModalGeneralParam(true, "Failed", `Failed: ${
              error.response.data.errors ? error.response.data.errors.data : ""
            }`, "warning");
          });
  }

  const showModalGeneralParam = (
    modalGeneralShow: boolean,
    title?: string,
    sub_title?: string,
    type?: ModalGeneralType | undefined
  ) => {
    const dataInfo: ModalGeneralParam = {
      title: title,
      sub_title: sub_title,
      modalGeneralShow: modalGeneralShow,
      type: type
    };
    setModalGeneralParam(dataInfo);
  };

  return (
    <div id="animate">
      <h1 className="page-header">Manual KYC</h1>
      <Panel>
        <PanelHeader noButton>Filber by</PanelHeader>
        <PanelBody>
          <p>Search by criteria:</p>
          <div className="row">
            <div className="col-md-5">
              <div className="form-check mb-2">
                <input
                  type="radio"
                  name="rdSearchCriteria"
                  className="form-check-input"
                  id="query_dni"
                  checked={state.query_type === 1 ? true : false}
                  onChange={(e) => {
                    setState({ ...state, ["query_type"]: 1 });
                  }}
                />
                <label className="form-check-label" htmlFor="query_dni">DNI</label>
              </div>
              <div className="form-check mb-2">
                <input
                  type="radio"
                  name="rdSearchCriteria"
                  className="form-check-input"
                  id="query_payout"
                  checked={state.query_type === 2 ? true : false}
                  onChange={(e) => {
                    setState({ ...state, ["query_type"]: 2 });
                  }}
                />
                <label className="form-check-label" htmlFor="query_payout">Payout ID</label>
              </div>
            </div>
          </div>
          <div className="row">
            <label className="form-label col-form-label col-md-1">Priority:</label>
            <div className="col-md-9 pt-2">
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  id="priority_customer"
                  className="form-check-input"
                  checked={state.priority_customer}
                  onChange={handleInputCheckChange}
                />
                <label className="form-check-label" htmlFor="priority_customer">Customer List</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  id="priority_reniec"
                  className="form-check-input"
                  checked={state.priority_reniec}
                  onChange={handleInputCheckChange}
                />
                <label className="form-check-label" htmlFor="priority_reniec">KYC Registry</label>
              </div>
            </div>
          </div>
        </PanelBody>
        <PanelHeader noButton>Input Data</PanelHeader>
        <PanelBody>
          <label className="form-label"><span>{state.query_type === 1 ? "DNI, Customer Name, CCI Bank Account" : "Payout ID"}</span></label>
          
          <div className="row">
            <div className="form-group col-md-10">
              <textarea
                name="input_data"
                className="form-control"
                rows={5}
                value={state.input_data}
                ref={txtInputData}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group col-md-2">
              <button
                className="btn btn-success btn-lg"
                onClick={handleValidateData}
              >
                Validate
              </button>
            </div>
          </div>
        </PanelBody>
        <PanelHeader noButton>Output of Validation</PanelHeader>
        <PanelBody>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td style={{width:"5%"}}>
                    <strong>Options</strong>
                  </td>
                  <td style={{width:"15%"}}>
                    <strong>DNI</strong>
                  </td>
                  <td style={{width:"25%"}}>
                    <strong>Full Name</strong>
                  </td>
                  <td style={{width:"15%"}}>
                    <strong>CCI Bank Account</strong>
                  </td>
                  <td style={{width:"10%"}}>
                    <strong>Source</strong>
                  </td>
                  <td style={{width:"25%"}}>
                    <strong>KYC/Registry full name</strong>
                  </td>
                  <td style={{width:"5%"}}>
                    <strong>Validation</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
              {typeof outputDataMQ !== `undefined`
                ? outputDataMQ.map((value: any, index: any) => {
                  return (
                    <tr className={index % 2 !== 0 ? "table-data" : "table-data-alter"}>
                      <td className="text-center">
                        {value.kyc_id !== 0 && (
                          <Link to={`${process.env.REACT_APP_Sub_Domain}/request/pending/search/view/${value.kyc_id}`} target="_blank" rel="noopener noreferrer">
                            <button
                              className="form-button-search"
                              onClick={() => {
                                //searchIconHandler(line);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="-3 -2 30 30"
                                fill="white"
                                width="18px"
                                height="18px"
                              >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                              </svg>
                            </button>
                          </Link>
                        )}&nbsp;
                        {value.show_edit == true && (
                          <Link to={`${process.env.REACT_APP_Sub_Domain}/request/pending/search/edit/${value.kyc_id}`} target="_blank" rel="noopener noreferrer">
                          <button
                            className="form-button-edit"
                            onClick={() => {
                              //editIconHandler(line);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="-1 3 24 24"
                              fill="white"
                              width="17px"
                              height="14px"
                            >
                              <path
                                d="M0 0h24v24H0V0z"
                                fill="none"
                              />
                              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
                            </svg>
                          </button>
                        </Link>
                        )}
                      </td>
                      <td>{value.dni}</td>
                      <td>{value.fullName}</td>
                      <td>{value.bankAccountNumber}</td>
                      <td>{value.sourceKYCRENIECFullName}</td>
                      <td>{value.kycreniecFullName}</td>
                      <td className="text-center">{value.validation}</td>
                    </tr>
                  );
                })
                : null}
              </tbody>
            </table>
          </div>
        </PanelBody>
      </Panel>
      {ModalGeneralParam.modalGeneralShow && (
          <ModalGeneral
            isModalOpen={ModalGeneralParam.modalGeneralShow}
            onlyModal={false}
            type={ModalGeneralParam.type!}
            title={ModalGeneralParam.title}
            desc={ModalGeneralParam.sub_title}
            confirmBtnText="Ok"
            onClose={ () => { 
              showModalGeneralParam(false);}
            }
            onConfirm={() => {
              showModalGeneralParam(false);
            }}
          ></ModalGeneral>
      )}
      {showProcessing && (
        <div className="backgroundProcessing">
          <div className="bpWaiting">Processing...</div>
        </div>
      )}
    </div>
  );
};

export default ManualQuery;