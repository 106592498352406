import React from "react";
import HttpCliente from "../../../services/HttpCliente";
import ReactLoading from 'react-loading';
import axios from "axios";
import { Panel, PanelBody, PanelHeader } from "../../../components/panel/panel.jsx";
import { Link } from "react-router-dom";
import { AppSettings } from "../../../config/app-settings";
import ModalGeneral, { ModalGeneralType } from "../../../components/modal/modalGeneral";

const NoteList = () => {
  const PageSettingsContext = React.useContext(AppSettings);

  const [per_page, set_per_page] = React.useState(50);
  const [page, set_page] = React.useState(0);
  const [notesData, setNotesData] = React.useState<any>([]);


  const [toggleRender, setToggleRender] = React.useState(false);
  const [alertType, setAlertType] = React.useState("");
  const [noteToHandle, setnoteToHandle] = React.useState("");
  const [newNotePopup, setNewNotePopup] = React.useState(false);
  const [sweetAlertActive, setSweetAlertActive] = React.useState(false);
  const [sweetAlertMessage, setSweetAlertMessage] = React.useState("Default");
  const [sweetAlertSuccess, setSweetAlertSuccess] = React.useState<ModalGeneralType>("info");
  const [sweetAlertSuccessBool, setSweetAlertSuccessBool] = React.useState(false);
  const [
    sweetAlertActiveVariant2,
    setSweetAlertActiveVariant2,
  ] = React.useState(false);
  const [
    sweetAlertMessage1Variant2,
    setSweetAlertMessage1Variant2,
  ] = React.useState("Temp");
  const [
    sweetAlertMessage2Variant2,
    setSweetAlertMessage2Variant2,
  ] = React.useState("Default");
  const [
    sweetAlertActiveVariant3,
    setSweetAlertActiveVariant3,
  ] = React.useState(false);
  const sweetAlertMessage1Variant3 = "Temp";
  const editButtonHandler = () => {
    setNewNotePopup(true);
  };
  const deleteButtonHandler = () => {
    setAlertType("deleteNote");
    toggleSweetAlertVariant2(
      "Are you sure?",
      "Do you want to delete this note?"
    );
  };
  const deleteActionProceed = () => {
    axios
      .delete(`/note/${noteToHandle}`)
      .then((response) => {
        //console.log(response);
        setnoteToHandle("");
        setToggleRender(!toggleRender);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const gotoPage = (value: any) => {
    set_page(value);
  };
  const nextPage = () => {
    if (typeof notesData !== "undefined") {
      if (page < notesData.last_page - 1) {
        set_page(page + 1);
      }
    }
  };
  const previousPage = () => {
    if (page > 0) set_page(page - 1);
  };
  ////////////////////////////////////////////////LOADING ANIMATION FUNCTION////////////////////////////////////////////////////////////////////////////////////////
  const loadingAnimation = () => {
    return (
      <tr>
        <td colSpan={2}>
          <div style={{ padding: "15vh 0% 15vh 38vw" }}>
            <ReactLoading type="spin" color="#00aeab" height={65} width={65} />
          </div>
        </td>
      </tr>
    );
  };
  const searchButtonHandler = () => {
    setNotesData(null);
    HttpCliente.get(
      `/note/?per_page=${per_page}&page=${
        page + 1
      }`
    )
      .then((response) => {
        setNotesData(response.data);
        //console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////////////////////////////////////////////////SWEET ALERTS TOGGLE HANDLER////////////////////////////////////////////////////////////////////////////////////////
  const toggleSweetAlert = (message: string, success: any, type: ModalGeneralType) => {
    setSweetAlertSuccessBool(success);
    setSweetAlertSuccess(type);
    setSweetAlertActive(!sweetAlertActive);
    setSweetAlertMessage(message);
  };

  const toggleSweetAlertVariant2 = (message: any, message2: any) => {
    setSweetAlertActiveVariant2(!sweetAlertActiveVariant2);
    setSweetAlertMessage1Variant2(message);
    setSweetAlertMessage2Variant2(message2);
  };

  React.useEffect(() => {
    searchButtonHandler();
  }, [toggleRender, newNotePopup, page]);

  let titlePage = "Note List";
  return (
    <div id="animate">
      <div>
        {sweetAlertActive ? (
          <ModalGeneral
            isModalOpen={sweetAlertActive}
            onlyModal={false}
            type={`${sweetAlertSuccess}`}
            onConfirm={() => {
              setSweetAlertActive(false);
            }}
            onClose={() => {
              setSweetAlertActive(false);
            }}
          >
            <div>
              <h3>{sweetAlertMessage}</h3>
            </div>
          </ModalGeneral>
        ) : null}
        {sweetAlertActiveVariant2 ? (
          <ModalGeneral
            isModalOpen={sweetAlertActiveVariant2}
            onlyModal={false}
            title={sweetAlertMessage1Variant2}
            type={"info"}
            onConfirm={() => {
              if (alertType === "deleteNote") {
                deleteActionProceed();
              }
              setSweetAlertActiveVariant2(false);
            }}
            onCancel={() => {
              if (alertType !== "nextRequest") {
              } else {
              }
              setAlertType("");
              setSweetAlertActiveVariant2(false);
            }}
            cancelBtnText="Cancel"
            confirmBtnText="Yes"
          >
            <h5>{sweetAlertMessage2Variant2}</h5>
          </ModalGeneral>
        ) : null}
        {sweetAlertActiveVariant3 ? (
          <ModalGeneral
            isModalOpen={sweetAlertActiveVariant3}
            onlyModal={false}
            type="info"
            title={sweetAlertMessage1Variant3}
            onConfirm={() => {
              if (alertType === "deleteNote") {
                deleteActionProceed();
              }
              setSweetAlertActiveVariant3(false);
            }}
            onCancel={() => {
              setAlertType("");
              setSweetAlertActiveVariant3(false);
            }}
            cancelBtnText="Cancel"
            confirmBtnText="Yes"
          />
        ) : null}
        <h1 className="page-header">{titlePage}</h1>
        <Panel>
        <PanelHeader noButton={true}>&nbsp;</PanelHeader>
          <PanelBody>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td>
                      <strong>Options</strong>
                    </td>
                    <td>
                      <strong>Description</strong>
                    </td>                   
                  </tr>
                </thead>
                <tbody>
                  {typeof notesData !== "undefined" &&
                  notesData !== null
                    ? typeof notesData.data !== "undefined" &&
                      notesData.data !== null
                      ? notesData.data.map((value: any, index: any) => {
                          return (
                            <tr
                              key={index}
                              className={
                                index % 2 !== 0
                                  ? "table-data"
                                  : "table-data-alter"
                              }
                            >
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <Link to={`${window.location.pathname}/view/${value.note_id}`}>
                                    <button
                                      className="form-button-search"
                                      onClick={() => {
                                        //searchIconHandler(line);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="-3 -2 30 30"
                                        fill="white"
                                        width="18px"
                                        height="18px"
                                      >
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                      </svg>
                                    </button>
                                  </Link>
                                  {[3, 5].includes(
                                    PageSettingsContext.userDataLogin.userData
                                      .user_tipo_id
                                  ) ? (
                                    <Link
                                      to={`${window.location.pathname}/edit/${value.note_id}`}
                                    >
                                      <button
                                        className="form-button-edit"
                                        onClick={() => {
                                          setnoteToHandle(
                                            value.note_id
                                          );
                                          editButtonHandler();
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="-1 3 24 24"
                                          fill="white"
                                          width="17px"
                                          height="14px"
                                        >
                                          <path
                                            d="M0 0h24v24H0V0z"
                                            fill="none"
                                          />
                                          <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
                                        </svg>
                                      </button>
                                    </Link>
                                  ) : null}
                                  {[3, 5].includes(
                                    PageSettingsContext.userDataLogin.userData
                                      .user_tipo_id
                                  ) ? (
                                    <button
                                      className="form-button-delete"
                                      onClick={() => {
                                        setnoteToHandle(value.note_id);
                                        deleteButtonHandler();
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0.5 2 24 24"
                                        fill="white"
                                        width="16px"
                                        height="16px"
                                      >
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z" />
                                      </svg>
                                    </button>
                                  ) : null}
                                </div>
                              </td>                              
                              <td>{value.description}</td>                            
                            </tr>
                          );
                        })
                      : null
                    : loadingAnimation()}
                </tbody>
              </table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <ul className="pagination mb-0">
                <li className="page-item">
                  <button className="page-link" onClick={() => gotoPage(0)}>
                    <i className="fa fa-angle-double-left"></i>
                  </button>
                </li>
                <li className="page-item">
                  <button className="page-link" onClick={() => previousPage()}>
                    <i className="fa fa-angle-left"></i>
                  </button>
                </li>
                <li className="page-item d-flex align-items-center px-2">
                  <div>
                    Page{" "}
                    {typeof notesData !== "undefined" &&
                    notesData !== null ? (
                      typeof notesData.data !== "undefined" &&
                      notesData.data !== null ? (
                        <strong>
                          {page + 1} of {notesData.last_page}
                        </strong>
                      ) : (
                        <strong>{page + 1} of 1</strong>
                      )
                    ) : (
                      <strong>{page + 1} of 1</strong>
                    )}
                  </div>
                </li>
                <li className="page-item">
                  <button className="page-link" onClick={() => nextPage()}>
                    <i className="fa fa-angle-right"></i>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link mx-2"
                    onClick={() => gotoPage(notesData.last_page - 1)}
                  >
                    <i className="fa fa-angle-double-right"></i>
                  </button>
                </li>
              </ul>
              {typeof notesData !== "undefined" &&
              notesData !== null ? (
                typeof notesData.data !== "undefined" &&
                notesData.data !== null ? (
                  <strong>
                    <div className="ml-3 mr-1 mx-2">{`Showing ${
                      notesData.per_page * notesData.current_page -
                      (notesData.per_page - 1)
                    } to ${
                      notesData.per_page * notesData.current_page
                    } of ${notesData.total_count} entries`}</div>
                  </strong>
                ) : (
                  <div className="ml-3 mr-1">{`Showing 0 to 0 of 0 entries`}</div>
                )
              ) : (
                <div className="ml-3 mr-1">{`Showing 0 to 0 of 0 entries`}</div>
              )}
              <div className="ml-3 mr-1">Go to page:</div>
              <div className="mx-2">
                <input
                  className="form-control"
                  type="number"
                  style={{ width:70 }}
                  defaultValue={page + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                />
              </div>
              <div>
                <select
                  className="form-select"
                  value={per_page}
                  onChange={(e) => {
                    set_per_page(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50, 60].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default NoteList;
