import { ListIDPage } from "../utils/autorizationHelper";

const Menu = [
  {
    path: `${process.env.REACT_APP_Sub_Domain}/request`,
    icon: "fa fa-id-card",
    title: "KYC Payouts",
    children: [
      {
        path: `${process.env.REACT_APP_Sub_Domain}/request/all/search`,
        title: "All",
      },
      {
        path: `${process.env.REACT_APP_Sub_Domain}/request/pending/search`,
        title: "Pending",
      },
      {
        path: `${process.env.REACT_APP_Sub_Domain}/request/tracking/search`,
        title: "Tracking",
      },
      {
        path: `${process.env.REACT_APP_Sub_Domain}/request/history/search`,
        title: "History",
      },
      {
        path: `${process.env.REACT_APP_Sub_Domain}/request/customer-validation/manual-query`,
        idPage: ListIDPage.ManualKYC,
        title: "Manual KYC",
      },
      {
        path: `${process.env.REACT_APP_Sub_Domain}/request/customer-ce-validation/manual-query`,
        idPage: ListIDPage.ManualCE,
        title: "Manual CE",
      },
      {
        path: `${process.env.REACT_APP_Sub_Domain}/request/customer-batch-validation/manual-batch-process`,
        idPage: ListIDPage.ManualBatchProcess,
        title: "Batch Process",
      },
    ],
  },
  {
    path: `${process.env.REACT_APP_Sub_Domain}/requestsource`,
    icon: "fa fa-id-card",
    title: "KYC Registry",
    children: [
      {
        path: `${process.env.REACT_APP_Sub_Domain}/requestsource/history/search`,
        title: "History",
      },
    ],
  },
  {
    path: `${process.env.REACT_APP_Sub_Domain}/customers`,
    icon: "fa fa-users",
    title: "Customers",
    children: [
      {
        path: `${process.env.REACT_APP_Sub_Domain}/customers/greyList/grey-list`,
        title: "Grey List",
      },
      {
        path: `${process.env.REACT_APP_Sub_Domain}/customers/greyList/new-grey-list`,
        idPage: ListIDPage.NewGreylist,
        title: "Add to Grey List",
      },
      {
        path: `${process.env.REACT_APP_Sub_Domain}/customers/list/customer-list`,
        title: "Customer List",
      },
      {
        path: `${process.env.REACT_APP_Sub_Domain}/customers/new/new-customer`,
        idPage: ListIDPage.NewCustomer,
        title: "New Customer",
      },

    ],
  },
  {
    path: `${process.env.REACT_APP_Sub_Domain}/notes`,
    icon: "fa fa-cogs",
    title: "Setups",
    children: [
      {
        path: `${process.env.REACT_APP_Sub_Domain}/notes/new/new-note`,
        idPage: ListIDPage.NewNote,
        title: "New Note",
      },
      {
        path: `${process.env.REACT_APP_Sub_Domain}/notes/list/note-list`,
        title: "Note List",
      },
    ],
  },
];

export default Menu;
