
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import React from "react";
import HttpCliente from "../../../services/HttpCliente";
import moment from "moment";
import axios from "axios";
import ModalGeneral, { ModalGeneralType } from "../../../components/modal/modalGeneral";

const NewNote = () => {
  let { kyc_id, edit_mode } = useParams();

  //if ( kyc_id === undefined) { window.location.reload();}

  let path = window.location.pathname;
  const [description, set_description] = React.useState("");
  const [customerData, setCustomerData] = React.useState([]);
  const [created_by_user, set_created_by_user] = React.useState("");
  const [created_at, set_created_at] = React.useState<any>("");
  const [updated_by_user, set_updated_by_user] = React.useState("");
  const [updated_at, set_updated_at] = React.useState<any>("");  
  const [borderOutlineColor, setborderOutlineColor] = React.useState("#d5dbe0");
  const [formComplete, setFormComplete] = React.useState(false);  
  const [action_mode, set_action_mode] = React.useState<any>("undefined");
  const [titlePage, set_titlePage] = React.useState("");

  //let titlePage = "New Note";  
  if (kyc_id !== undefined) {
    path = path.slice(0, path.lastIndexOf("/"));
    path = path.slice(0, path.lastIndexOf("/"));    
  }

  const [note_id, set_note_id] = React.useState(
    kyc_id !== undefined ? kyc_id : 0
  );


  React.useEffect(() => {
   
    if (kyc_id !== undefined) {    
      set_titlePage("Edit Note");
      if (edit_mode === "view") {        
        set_titlePage("View Note");   
      }
      set_action_mode(edit_mode);
    }else{
      const segment = path.substring(path.lastIndexOf('/') + 1);
      if (segment === "new-note") {
        set_action_mode(segment);          
        set_titlePage("New Note"); 
      }
    }
  }, []);



  const saveButtonPostRequest = () => {
    let sigData;

    sigData = {
           description: description
    };
    axios
      .post(`/note`, sigData)
      .then((response) => {
        set_note_id(response.data.note_id);
        setFormComplete(true);
        toggleSweetAlert("Success!", true, "success");
      })
      .catch((error) => {
        console.log(error);
        toggleSweetAlert(`Error: ${error}`, false, "danger");
      });
  };

  const saveButtonHandler = () => {
    if (
      description === ""
    ) {
      toggleSweetAlert("Please complete all mandatory fields", false, "danger");
      setborderOutlineColor("red");
    } else if (description.length>200){
      toggleSweetAlert("Text has exceeded the maximum of 200 characters.", false, "danger");
      setborderOutlineColor("red"); 
    } else {
      if(action_mode==='new-note'){
        saveButtonPostRequest();
      } else if(action_mode==='edit'){
        saveButtonPatchRequest();
      }
    }
  };

  const saveButtonPatchRequest = () => {
    let sigData;
    sigData = {
      note_id: note_id,
      description: description
    };

    axios
      .patch(`/note/${note_id}`, sigData)
      .then((response) => {
        setFormComplete(true);
        toggleSweetAlert("Success!", true, "success");
      })
      .catch((error) => {
        toggleSweetAlert(`Error: ${error}`, false, "danger");
      });
  };

  const toggleSweetAlert = (message: string, success: any, type: ModalGeneralType) => {
    setSweetAlertSuccessBool(success);
    setSweetAlertSuccess(type);
    setSweetAlertActive(!sweetAlertActive);
    setSweetAlertMessage(message);
  };

    const [sweetAlertSuccessBool, setSweetAlertSuccessBool] =
    React.useState(false);
    const [sweetAlertSuccess, setSweetAlertSuccess] = React.useState<ModalGeneralType>("info");
    const [sweetAlertActive, setSweetAlertActive] = React.useState(false);
    const [sweetAlertMessage, setSweetAlertMessage] = React.useState("Default");


  React.useEffect(() => {
    HttpCliente.get(`/note`)
      .then((response) => {
        setCustomerData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    if (note_id !== 0) { 
      HttpCliente.get(`/note/${note_id}`)
        .then((response) => {
          console.log(response);
          set_description(response.data.description);        
          set_created_by_user(response.data.created_by_user);
          set_created_at(response.data.created_at);
          set_updated_by_user(response.data.updated_by_user);
          set_updated_at(response.data.updated_at);
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  }, [1]);


  return (
    <div id="animate">     
      {sweetAlertActive ? (
        <ModalGeneral
          isModalOpen={sweetAlertActive}
          onlyModal={false}
          title={""}
          type={sweetAlertSuccess}
          onConfirm={() => {
            if (formComplete === true) {
              if (typeof kyc_id !== `undefined`) {
                if (typeof window !== `undefined`) {
                  window.location.href = path;
                }
              } else if (typeof window !== `undefined`) {
                window.location.href = `${process.env.REACT_APP_Sub_Domain}/notes/list/note-list`;
              }
            }
            setSweetAlertActive(false);
          }}
          onClose={() => {
            setSweetAlertActive(false);
          }}
        >
          <div>
            <h3>{sweetAlertMessage}</h3>
          </div>
        </ModalGeneral>
      ) : null}
      <h1 className="page-header">{titlePage}</h1>
      <Panel>
      <PanelHeader noButton={true}>&nbsp;</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label mb-2" htmlFor="InputCustomer">
                <strong>Description</strong>
              </label>
              <input
                style={{
                  border: `solid 1px ${
                    description === "" ? borderOutlineColor : "#d5dbe0"
                  }`,
                }}
                type="text"
                className="form-control"
                id="InputCustomer"
                placeholder="Name"
                disabled={action_mode === "view" ? true : false}
                value={description}
                onChange={(e) => {
                  set_description(e.target.value);
                }}
              />
            </div>   
          </div>
        </PanelBody>    
        <PanelBody>
          <div
            className="panel-button-row"
            style={{ padding: "0px 5px 0px 0px" }}
          >
            {note_id !== 0 ? (
              <div className="row">
                {created_by_user !== null && action_mode !== 'new-note' ? (
                  <label>{`Created by ${created_by_user} at ${moment
                    .unix(created_at)
                    .format("DD/MM/YY hh:mm A")}`}</label>
                ) : null}
                <br />
                {updated_by_user !== null && action_mode !== 'new-note' ? (
                  <label>{`Updated by ${updated_by_user} at ${moment
                    .unix(updated_at)
                    .format("DD/MM/YY hh:mm A")}`}</label>
                ) : null}
              </div>
            ) : (
              <div />
            )}
            <div>
              {action_mode !== 'new-note' ? (
                <Link to={`${path}`} style={{ paddingRight: "5px" }}>
                  <button
                    className="panel-iconbutton-close"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="18px"
                      height="18px"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                    </svg>
                    <strong>Close</strong>
                  </button>
                </Link>
              ) : null}
              {action_mode !== "view" ? (
                <button
                  className="panel-iconbutton-save"
                  style={{ marginRight: "-5px" }}
                  onClick={() => {
                    saveButtonHandler();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="18px"
                    height="18px"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                  </svg>
                  <strong>Save</strong>
                </button>
              ) : null}
            </div>
          </div>
        </PanelBody>      
      </Panel>
    </div>
  );
};

export default NewNote;
