import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppSettings } from "../../config/app-settings";
import { storageInteractor } from '../../utils/storageInteractor';

const objStorageInteractor = new storageInteractor();

function PageLoad() {
	const PageSettingsContext = React.useContext(AppSettings);
	const navigate = useNavigate();


	useEffect(() => {
    PageSettingsContext.handleSetAppSidebarNone(true);
    PageSettingsContext.handleSetAppHeaderNone(true);
    PageSettingsContext.handleSetAppContentClass('p-0');

		let token_security: string | null =
		objStorageInteractor.getTokenSecurity();

		if (token_security == null) {
			navigate(`${process.env.REACT_APP_Sub_Domain}/Login`);
		}

		//
    return() => {
      PageSettingsContext.handleSetAppSidebarNone(false);
      PageSettingsContext.handleSetAppHeaderNone(false);
      PageSettingsContext.handleSetAppContentClass('');
    }
  }, []);

	return (
		<></>
	)
}

export default PageLoad;