import "./style.scss";
import React from "react";
import { Panel, PanelHeader, PanelBody } from "../panel/panel";
import HttpCliente from "../../services/HttpCliente";

const StatusResetPopup = (props) => {
  const {
    toggle,
    resetList,
    setResetList,
    toggleSweetAlert,
    triggerRender,
    setTriggerRender,
    onlytoPending,
  } = props;
  const [resetStatus, setResetStatus] = React.useState(1);
  const [commentsText, setCommentsText] = React.useState("");

  const sigData = {
    comment: commentsText,
    status_id: parseInt(resetStatus, 10),
  };
  React.useEffect(() => {
    if(onlytoPending){
      setResetStatus(3);
    }
  }, [resetStatus]);

  const resetFormProcessHandler = () => {
    
    if(onlytoPending){
      setResetStatus(3);
    }
    console.log(onlytoPending);
    console.log(resetStatus);
    if (!(resetStatus == 3 || resetStatus == 1)  || commentsText === "") {
      toggleSweetAlert("Please complete all fields", false, "info");
    } else {
      resetList.map((item) => {
        HttpCliente.post(`/request/${item}/history`, sigData)
          .then((response) => {
            toggle(false);
            toggleSweetAlert(
              "The status was resetted successfully",
              true,
              "success"
            );
            setResetList([]);
            setTriggerRender(!triggerRender);
            toggle(false);
          })
          .catch((error) => {
            // toggleSweetAlert(
            //   `Error: Something went wrong`,
            //   false,
            //   "danger"
            // );
          });
      });
    }
  };
  return (
    <div>
      <div id="animate" className="wrapper">
        {alert}
        <Panel>
          <PanelHeader noButton>Status Reset</PanelHeader>
          <PanelBody>
            <div>
              <div className="row">
                <div className="col-md-12 mb-3">
                {onlytoPending?(
                      <>
                        <label class="form-label mb-2" htmlFor="NewStatusInput">
                        Reset to pending
                        </label>
                      </>

                    ):
                    (
                      <>
                        <label class="form-label mb-2" htmlFor="NewStatusInput">
                          New status for the selected KYC requests
                        </label>
                        <br />
                        <select
                          className="form-select"
                          defaultValue="Pending"
                          name="status-input"
                          id="NewStatusInput"
                          onChange={(e) => {
                            setResetStatus(e.target.value);
                          }}
                        >
                          <option value={1}>Pending</option>
                          <option value={3}>Process</option>
                        </select>
                      </>
                    )
                    }

                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label class="form-label mb-2" htmlFor="CommentsInput">
                      Comments
                  </label>
                  <textarea
                    className="form-control"
                    type="text"
                    name="comments"
                    id="CommentsInput"
                    onChange={(e) => {
                      setCommentsText(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="panel-button-row">
                <button
                  className="panel-iconbutton-close"
                  onClick={() => toggle(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="18px"
                    height="18px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                  </svg>
                  <strong>Close</strong>
                </button>
                <button
                  className="panel-button"
                  onClick={() => resetFormProcessHandler()}
                >
                  <strong>Submit</strong>
                </button>
              </div>
            </div>
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default StatusResetPopup;
