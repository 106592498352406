import './style.scss';

const TabbedPanel = (props) => {
  const { children, tabs, activeTab, setActiveTab } = props;
  return (
    <div id="animate">
      <div className="tabs-row">
        {tabs.map((tabName, index) => { return (
          <div key={index}
            className={index === activeTab ? "tab" : "inactive-tab"}
            onClick={() => setActiveTab(index)}
          >
            {tabName}
          </div>
        )})}
      </div>
      <div className="tab-wrapper">
        {children}
      </div>
    </div>
  );
}

export default TabbedPanel;
