import "./style.scss";
import React from "react";
import { Panel, PanelHeader, PanelBody } from "../panel/panel";
import HttpCliente from "../../services/HttpCliente";

const NewCommentPopup = (props) => {
  const {
    toggle,
    current_kyc_id,
    statusRequestData,
    toggleSweetAlert,
    status_id,
  } = props;
  // const [submitStatus, toggleSubmitStatus] = React.useState(false);
  // const [submitSuccess, setSubmitSuccess] = React.useState(true);
  // const [resetStatus, setResetStatus] = React.useState(1);
  const [commentsText, setCommentsText] = React.useState("");
  const sigData = {
    comment: commentsText,
    status_id: 3,
  };
  const newCommentHandler = () => {
    if (commentsText === "") {
      toggleSweetAlert("Please complete all fields", false, "info");
    } else {
      HttpCliente.post(`/request/${current_kyc_id}/history`, sigData)
        .then((response) => {
          // console.log(response);
          toggleSweetAlert("Comment Added", true, "success");
          toggle(false);
        })
        .catch((error) => {
          toggleSweetAlert(
            `Error: ${
              error.response.data.errors
                ? error.response.data.errors.message
                : "Failed"
            }`,
            false,
            "danger"
          );
        });
    }
  };
  return (
    <div>
      <div id="animate" className="wrapper">
        <Panel>
          <PanelHeader noButton>Add Comment</PanelHeader>
          <PanelBody>
            <div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label class="form-label mb-2" htmlFor="currentStatusInput">
                    <strong>Current Status</strong>
                  </label>
                  <select
                    disabled={true}
                    className="form-select"
                    id="currentStatusInput"
                    placeholder="Process"
                    value={status_id}
                  >
                    {typeof statusRequestData !== `undefined`
                      ? statusRequestData.map((value, index) => (
                          <option
                            value={value.requestStatusId}
                            key={value.requestStatusId}
                          >
                            {value.requestStatusName}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label class="form-label mb-2" htmlFor="CommentsInput">
                    <strong>Comments</strong>
                  </label>
                  <textarea
                    className="form-control"
                    type="text"
                    name="comments"
                    id="CommentsInput"
                    onChange={(e) => {
                      setCommentsText(e.target.value);
                    }}
                  />
                </div>
              </div>
              
              <div className="panel-button-row">
                <button
                  className="panel-iconbutton-close"
                  onClick={() => toggle(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="18px"
                    height="18px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                  <strong>Cancel</strong>
                </button>
                <button
                  className="panel-iconbutton-save"
                  onClick={() => newCommentHandler()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="18px"
                    height="18px"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                  </svg>
                  <strong>Save</strong>
                </button>
              </div>
            </div>
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default NewCommentPopup;
