import React, { ReactNode } from "react";
import ReactModal from "react-modal";
import "./modalGeneral.scss";

export declare type ModalGeneralType = "info" | "success" | "warning" | "danger";

/*
type ModalGeneralProps = {
  onConfirm?: () => void;
  onCancel?: () => void;
  onRequestClose?: () => void;
  type?: ModalGeneralType;
  children?: ReactNode;
  onlyModal?: boolean;
  title?: string;
  desc?: string;
}
*/

interface ModalGeneralProps {
  children?: ReactNode;
  type?: ModalGeneralType;
  title?: string;
  desc?: string;
  isModalOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  onlyModal: boolean;
  addZIndexTop?: boolean;
}

ReactModal.setAppElement('#root');

const customStyleModalYesNo = {
  content: {
    padding: "35px"
  },
};

const customStylesOnlyModal = {
  content: {
    padding: "0px"
  },
};

const ModalGeneral: React.FC<ModalGeneralProps> = ({type, title, desc, isModalOpen, confirmBtnText, cancelBtnText, 
  onlyModal, addZIndexTop, onClose, onConfirm, onCancel, children}) => {
  
    const SetContentData = () => {
      if (onlyModal) {
        return (
          <>
          {children}
          </>
        );
      }

      let contentDataIcon = <></>;
      let contentDataTitle = <></>;
      let contentDataSubTitle = <></>;
      let contentButtons = <></>;
      let bntConfirmClass: string = "btn btn-lg ";

      switch (type) {
        case "info":
          contentDataIcon = (
            <div className="icon-info-h">
              <div className="info-r1"></div>
              <div className="info-r2"></div>
            </div>
          );
          bntConfirmClass += "btn-info";
          break;
        case "warning":
          contentDataIcon = (
            <div className="icon-warning-h">
              <span className="warning-sp1"></span>
              <span className="warning-sp2"></span>
            </div>
          );
          bntConfirmClass += "btn-warning";
          break;
        case "success":
          contentDataIcon = (
            <div className="icon-success-h">
              <div className="success-r1"></div>
              <span className="success-r1-sp1"></span>
              <span className="success-r1-sp2"></span>
              <div className="success-r2"></div>
              <div className="success-r3"></div>
              <div className="success-r4"></div>
            </div>
          );
          bntConfirmClass += "btn-success";
          break;
        case "danger":
          contentDataIcon = (
            <div className="icon-error-h">
              <span className="error-sp1">
                <span className="error-sp1-s1"></span>
                <span className="error-sp1-s2"></span>
              </span>
            </div>
          );
          bntConfirmClass += "btn-danger";
          break;
      }
      contentDataTitle = <h2 className="modal-title">{title}</h2>;
      contentDataSubTitle = (contentDataSubTitle !== undefined) ? <div className="modal-sub-title">{desc}</div> : <></>;

      confirmBtnText = (confirmBtnText === undefined) ? "Ok" : confirmBtnText;
      let btnModalCancel = (cancelBtnText === undefined) ? <></> : <button onClick={onCancel} className="btn btn-lg btn-danger me-3">{cancelBtnText}</button>

      contentButtons = <p className="modal-buttons">
        {btnModalCancel}
        <button onClick={onConfirm} className={bntConfirmClass}>{confirmBtnText}</button>
      </p>

      return (
        <>
          {contentDataIcon}
          {contentDataTitle}
          {contentDataSubTitle}
          {children}
          {contentButtons}
        </>
      );
    }

  return (
    <ReactModal
      isOpen={isModalOpen}
      className="Modal"
      overlayClassName={`Overlay ${addZIndexTop ? "OverlayAddTop" : ""}`}
      onRequestClose={onClose}
      style={onlyModal ? customStylesOnlyModal : customStyleModalYesNo}
      >
      {SetContentData()}
    </ReactModal>
  );
}

export default ModalGeneral;
