import React from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import moment from "moment";
import HttpCliente from "../../services/HttpCliente";
import "./style.scss";
import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RequestSourceForm = (parm) => {
  let { kyc_id } = useParams();
  let path = window.location.pathname;

  React.useEffect(() => {

    const onPageLoad = () => {
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  path = path.slice(0, path.lastIndexOf("/"));
  path = path.slice(0, path.lastIndexOf("/"));
  ////////////////////////////////////////////////DECLARING STATE VARIABLE////////////////////////////////////////////////////////////////////////////////////////
  const [
    kyc_status_customer_name,
    set_kyc_status_customer_name,
  ] = React.useState('');
  const [customer_dob, set_customer_dob] = React.useState('');
  const [customer_name, set_customer_name] = React.useState('');
  const [customer_id_number, set_customer_id_number] = React.useState('');
  const [customer_id_type, set_customer_id_type] = React.useState('');
  const [customer_province, set_customer_province] = React.useState('');
  const [customer_department, set_customer_department] = React.useState('');

  const [id, set_id] = React.useState('');
  const [kyc_status_customer_id, set_kyc_status_customer_id] = React.useState('');
  const [type_source, set_type_source] = React.useState('');
  const [kyc_status_notes, set_kyc_status_notes] = React.useState('');
  const [kyc_status, set_kyc_status_id] = React.useState('');

  const [partner_id, set_partner_id] = React.useState('');
  const [request_date, set_request_date] = React.useState('');
  const [request_reference, set_request_reference] = React.useState('');
  const [kyc_code, set_kyc_code] = React.useState('');
  const [status_id, set_status_id] = React.useState('');
  const [kyc_status_dob, set_kyc_status_dob] = React.useState('');
  
  const [statusKycData, setStatusKycData] = React.useState();
  const [typeSourceData, setTypeSourceData] = React.useState();
  const [partnerData, setPartnerData] = React.useState();

  ////////////////////////////////////////////////SETTING UP COMPONENT MOUNTING DATA////////////////////////////////////////////////////////////////////////////////////////

  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
    HttpCliente.get(`/requestsource/${kyc_id}`)
      .then((response) => {
        const dobUnix = response.data.customer_dob;
        let dob = null;
    
        if (dobUnix) {
          const date = moment.unix(dobUnix).toDate();
          dob = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
          );
        }
    
        set_customer_dob(dob);

        set_customer_name(response.data.customer_name);
        set_customer_id_number(response.data.customer_id_number);
        set_customer_id_type(response.data.customer_id_type);
        set_customer_department(response.data.adress_department=== null ? "" :response.data.adress_department );
        set_customer_province(response.data.adress_province=== null ? "" : response.data.adress_province);
        set_id(response.data.id);
        set_kyc_status_customer_id(response.data.kyc_status_customer_id);
        set_kyc_status_dob(response.data.kyc_status_dob);
        set_kyc_status_customer_name(response.data.kyc_status_customer_name);
        set_kyc_status_id(response.data.kyc_status);
        set_type_source(response.data.request_type_source);
        set_kyc_status_notes(response.data.kyc_status_notes === null ? "" : response.data.kyc_status_notes);
        set_partner_id(response.data.partner_id);
        set_request_date(response.data.request_date);
        set_request_reference(response.data.request_reference);
        set_kyc_code(response.data.kyc_id);
        set_status_id(response.data.status_id === 1 ? 1 : 2);
      })
      .catch((error) => {
        console.log(error.response);
      });


    HttpCliente.get(`/status/kyc`)
      .then((response) => {
        setStatusKycData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    HttpCliente.get(`/partner`)
      .then((response) => {
        setPartnerData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    HttpCliente.get(`/typesource`)
      .then((response) => {
        setTypeSourceData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [kyc_id, status_id]);
  
  return (
    <div id="animate">
      <h1 className="page-header">KYC Registry Request</h1>
       <Panel>
        <PanelHeader noButton className="panel-heading">
          Main Info
        </PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>KYC #</strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputKycNumber"
                value={id}
              />
            </div>
             <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Date / Time</strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputDateFrom"
                min="1997-01-01"
                max="2030-12-29"
                placeholder={moment
                  .unix(request_date)
                  .format("DD/MM/YYYY hh:mm A")}
              />
            </div> 
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Request Status</strong>
              </label>
              <select
                disabled={true}
                className="form-select"
                id="InputRequestStatus"
                value={status_id}
              >
                  <option value={1}>Error</option>
                  <option value={2}>Ok</option>
              </select>
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Client</strong>
              </label>
              <select
                disabled={true}
                className="form-select"
                id="InputClient4"
                value={partner_id}
              >
                {typeof partnerData !== `undefined`
                  ? partnerData.map((value, index) => (
                    <option value={value.partnerID} key={value.partnerID}>
                      {value.partnerName}
                    </option>
                  ))
                  : null}
              </select>
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Request Reference</strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputRequestReference"
                value={request_reference}
              />
            </div>

            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>KYC: Status</strong>
              </label>
              <br />
              <select
                disabled={true}
                className="form-select"
                name="kyc-id-status"
                id="InputKYCStatus"
                value={kyc_status}
              >
                {typeof statusKycData !== `undefined`
                  ? statusKycData.map((value, index) => (
                    <option
                      value={value.kycStatusId}
                      key={value.kycStatusId}
                    >
                      {value.kycStatusName}
                    </option>
                  ))
                  : null}
              </select>
            </div>

            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Source</strong>
              </label>
              <select
                disabled={true}
                className="form-select"
                id="InputTypeSource"
                value={type_source}
              >
                {typeof typeSourceData !== `undefined`
                  ? typeSourceData.map((value, index) => (
                    <option value={value.typeSourceId} key={value.typeSourceName}>
                      {value.typeSourceName}
                    </option>
                  ))
                  : null}
              </select>
            </div>

            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>KYC Registry Id </strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputKYCId"
                value={kyc_code}
              />
            </div>

          </div>
        </PanelBody>
      </Panel>
      <Panel>
        <PanelHeader noButton>Customer Identification</PanelHeader>
        <PanelBody>
          <div className="row">
           <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>ID. Number</strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputIdNumber"
                value={customer_id_type + " " + customer_id_number}
              />
            </div> 
            <div className="mb-3 col-md-4">
              <label className="form-label"  htmlFor="InputCustomerName">
                <strong>Customer Name</strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputCustomerName"
                value={customer_name}
              />
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Date of Birth</strong>
              </label>


              <form autoComplete="off">
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  id="InputDateTo"
                  placeholderText={""}
                  selected={customer_dob}
                  disabled={true} />
              </form>
            </div>  
           

            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Department</strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputDepartment"
                value={customer_department}
              />
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Province</strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputProvince"
                value={customer_province}
              />
            </div> 

          

          </div>
          <div className="row">
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>ID:Status</strong>
              </label>
              <select
                disabled={true}
                type="text"
                className="form-select"
                id="InputKYCIdStatus"
                value={kyc_status_customer_id}
              >
                {typeof statusKycData !== `undefined`
                  ? statusKycData.map((value, index) => (
                    <option
                      value={value.kycStatusId}
                      key={value.kycStatusId}
                    >
                      {value.kycStatusName}
                    </option>
                  ))
                  : null}
              </select>
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Name:Status</strong>
              </label>
              <select
                disabled={true}
                type="text"
                className="form-select"
                id="InputKYCNameStatus"
                value={kyc_status_customer_name}
              >
                {typeof statusKycData !== `undefined`
                  ? statusKycData.map((value, index) => (
                    <option
                      value={value.kycStatusId}
                      key={value.kycStatusId}
                    >
                      {value.kycStatusName}
                    </option>
                  ))
                  : null}
              </select>
            </div> 

            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Date of Birth:Status</strong>
              </label>
              <select
                disabled={true}
                type="text"
                className="form-select"
                id="InputDOBStatus"
                value={kyc_status_dob || ""}
              >
                <option value=""></option>
                {typeof statusKycData !== `undefined`
                  ? statusKycData.map((value, index) => (
                    <option
                      value={value.kycStatusId}
                      key={value.kycStatusId}
                    >
                      {value.kycStatusName}
                    </option>
                  ))
                  : null}
              </select>
            </div> 
          </div>
        </PanelBody>
      </Panel>
      <Panel>
        <PanelHeader noButton>Notes</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label">
                <strong>System Notes</strong>
              </label>
              <br />
              <textarea
                disabled={true}
                className="form-control"
                id="InputKYCNotes"
                value={kyc_status_notes}
              />
            </div>
          </div>
        </PanelBody>
      </Panel> 
      <div className="panel-button-row">
        <div>
          <Link to={`${path}`}>
            <button
              className="panel-iconbutton-close"
              onClick={() => {
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                width="18px"
                height="18px"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
              </svg>
              <strong>Close</strong>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RequestSourceForm;
