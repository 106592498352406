import React from "react";
import HttpCliente from "../../../services/HttpCliente";
import fileDownload from "js-file-download";
import ReactLoading from 'react-loading';
import axios from "axios";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel.jsx";
import { Link } from "react-router-dom";
import { AppSettings } from "../../../config/app-settings";
import ModalGeneral, { ModalGeneralType } from "../../../components/modal/modalGeneral";

const CustomerList = () => {
  const PageSettingsContext = React.useContext(AppSettings);
  const url = window.location.pathname;

  const [per_page, set_per_page] = React.useState(50);
  const [page, set_page] = React.useState(0);
  const [customerData, setCustomersData] = React.useState<any>([]);
  //////////////////////////////////////////////////////////////////INPUT FIELDS/////////////////////////////////////////////////////////////
  const [customer, set_customer] = React.useState(
    PageSettingsContext.customer_cs
  );
  const [bank_account, set_bank_account] = React.useState(
    PageSettingsContext.bank_account_cs
  );
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [toggleRender, setToggleRender] = React.useState(false);
  const [alertType, setAlertType] = React.useState("");
  const [customerToHandle, setcustomerToHandle] = React.useState("");
  const [newCustomerPopup, setNewCustomerPopup] = React.useState(false);
  const [sweetAlertActive, setSweetAlertActive] = React.useState(false);
  const [sweetAlertMessage, setSweetAlertMessage] = React.useState("Default");
  const [sweetAlertSuccess, setSweetAlertSuccess] = React.useState<ModalGeneralType>("info");
  const [sweetAlertSuccessBool, setSweetAlertSuccessBool] = React.useState(
    false
  );
  const [
    sweetAlertActiveVariant2,
    setSweetAlertActiveVariant2,
  ] = React.useState(false);
  const [
    sweetAlertMessage1Variant2,
    setSweetAlertMessage1Variant2,
  ] = React.useState("Temp");
  const [
    sweetAlertMessage2Variant2,
    setSweetAlertMessage2Variant2,
  ] = React.useState("Default");
  const [
    sweetAlertActiveVariant3,
    setSweetAlertActiveVariant3,
  ] = React.useState(false);
  const sweetAlertMessage1Variant3 = "Temp";
  // const [
  //   sweetAlertMessage1Variant3,
  //   setSweetAlertMessage1Variant3,
  // ] = React.useState("Temp");
  const editButtonHandler = () => {
    setNewCustomerPopup(true);
  };
  const deleteButtonHandler = () => {
    setAlertType("deleteCustomer");
    toggleSweetAlertVariant2(
      "Are you sure?",
      "Do you want to delete this customer?"
    );
  };
  const deleteActionProceed = () => {
    axios
      .delete(`/customer/${customerToHandle}`)
      .then((response) => {
        //console.log(response);
        setcustomerToHandle("");
        setToggleRender(!toggleRender);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const clearButtonHandler = () => {
    setCustomersData(null);
    set_customer("");
    set_bank_account("");
    PageSettingsContext.set_customer_cs("");
    PageSettingsContext.set_bank_account_cs("");
    setToggleRender(!toggleRender);
  };
  const exportButtonHandler = () => {
    const hideClass = "hide";
    document.getElementById("prgLoading")!.className = "show";
    axios({
      url: `/customer/export/?customer=${customer}&bank_account=${bank_account}&time_zone_off_set=${-(
        new Date().getTimezoneOffset() / 60
      )}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        //console.log(response);
        fileDownload(response.data, "customers.xlsx");
        toggleSweetAlert(`Download Started!`, true, "success");
        document.getElementById("prgLoading")!.className = hideClass;
      })
      .catch((error) => {
        document.getElementById("prgLoading")!.className = hideClass;
        toggleSweetAlert(`${error}`, false, "danger");
      });
  };
  ////////////////////////////////////////////////PAGINATION HANDLERS////////////////////////////////////////////////////////////////////////////////////////

  const gotoPage = (value: any) => {
    set_page(value);
  };
  const nextPage = () => {
    if (typeof customerData !== "undefined") {
      if (page < customerData.last_page - 1) {
        set_page(page + 1);
      }
    }
  };
  const previousPage = () => {
    if (page > 0) set_page(page - 1);
  };
  ////////////////////////////////////////////////LOADING ANIMATION FUNCTION////////////////////////////////////////////////////////////////////////////////////////
  const loadingAnimation = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div style={{ padding: "15vh 0% 15vh 38vw" }}>
              <ReactLoading type="spin" color="#00aeab" height={65} width={65} />
          </div>
        </td>
      </tr>
    );
  };
  const searchButtonHandler = () => {
    setCustomersData(null);
    HttpCliente.get(
      `/customer/?customer=${customer}&bank_account=${bank_account}&per_page=${per_page}&page=${
        page + 1
      }`
    )
      .then((response) => {
        setCustomersData(response.data);
        //console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////////////////////////////////////////////////SWEET ALERTS TOGGLE HANDLER////////////////////////////////////////////////////////////////////////////////////////
  const toggleSweetAlert = (message: string, success: any, type: ModalGeneralType) => {
    setSweetAlertSuccessBool(success);
    setSweetAlertSuccess(type);
    setSweetAlertActive(!sweetAlertActive);
    setSweetAlertMessage(message);
  };

  const toggleSweetAlertVariant2 = (message: any, message2: any) => {
    setSweetAlertActiveVariant2(!sweetAlertActiveVariant2);
    setSweetAlertMessage1Variant2(message);
    setSweetAlertMessage2Variant2(message2);
  };

  React.useEffect(() => {
    searchButtonHandler();
  }, [toggleRender, newCustomerPopup, page]);

  let titlePage = "Customer List";
  return (
    <div id="animate">
      <div>
        {sweetAlertActive ? (
          <ModalGeneral
            isModalOpen={sweetAlertActive}
            onlyModal={false}
            type={`${sweetAlertSuccess}`}
            onConfirm={() => {
              setSweetAlertActive(false);
            }}
            onClose={() => {
              setSweetAlertActive(false);
            }}
          >
            <div>
              <h3>{sweetAlertMessage}</h3>
            </div>
          </ModalGeneral>
        ) : null}
        {sweetAlertActiveVariant2 ? (
          <ModalGeneral
            isModalOpen={sweetAlertActiveVariant2}
            onlyModal={false}
            type="info"
            title={sweetAlertMessage1Variant2}
            onConfirm={() => {
              if (alertType === "deleteCustomer") {
                deleteActionProceed();
              }
              setSweetAlertActiveVariant2(false);
            }}
            onCancel={() => {
              if (alertType !== "nextRequest") {
              } else {
              }
              setAlertType("");
              setSweetAlertActiveVariant2(false);
            }}
            cancelBtnText="Cancel"
            confirmBtnText="Yes"
          >
            <h5>{sweetAlertMessage2Variant2}</h5>
          </ModalGeneral>
        ) : null}
        {sweetAlertActiveVariant3 ? (
          <ModalGeneral
            isModalOpen={sweetAlertActiveVariant3}
            onlyModal={false}
            title={sweetAlertMessage1Variant3}
            type="info"
            onConfirm={() => {
              if (alertType === "deleteCustomer") {
                deleteActionProceed();
              }
              setSweetAlertActiveVariant3(false);
            }}
            onCancel={() => {
              setAlertType("");
              setSweetAlertActiveVariant3(false);
            }}
            cancelBtnText="Cancel"
            confirmBtnText="Yes"
          />
        ) : null}
        <h1 className="page-header">{titlePage}</h1>
        <Panel>
          <PanelHeader noButton>Filter By</PanelHeader>
          <PanelBody>
            <div className="row">
              <div className="col-md-3">
                <label className="form-label" htmlFor="InputCustomer">
                  <strong>Customer</strong>
                </label>
                <input
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      searchButtonHandler();
                    }
                  }}
                  type="text"
                  className="form-control"
                  id="InputCustomer"
                  placeholder="Name / DNI"
                  value={customer}
                  onChange={(e) => {
                    set_customer(e.target.value);
                    PageSettingsContext.set_customer_cs(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <label className="form-label" htmlFor="InputBankAccount">
                  <strong>Bank Account</strong>
                </label>
                <input
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      searchButtonHandler();
                    }
                  }}
                  type="text"
                  className="form-control"
                  id="InputBankAccount"
                  placeholder="Local / CCI"
                  value={bank_account}
                  onChange={(e) => {
                    set_bank_account(e.target.value);
                    PageSettingsContext.set_bank_account_cs(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className="panel-button-row"
              style={{ padding: "0px 5px 0px 15px" }}
            >
              <div />
              <div>
                <button
                    className="panel-iconbutton-clear"
                    onClick={() => {
                      clearButtonHandler();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="20px"
                      height="20px"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z" />
                    </svg>
                    <strong>Clear</strong>
                  </button>
                <button
                  className="panel-iconbutton"
                  onClick={() => {
                    exportButtonHandler();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="20px"
                    height="20px"
                  >
                    <g>
                      <path d="M0,0h24v24H0V0z" fill="none" />
                    </g>
                    <g>
                      <g>
                        <path d="M18,2h-8L4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M18,4v16H6V8.83L10.83,4H18z" />
                        <path d="M16,13l-4,4l-4-4l1.41-1.41L11,13.17V9.02L13,9v4.17l1.59-1.59L16,13z" />
                      </g>
                    </g>
                  </svg>
                  <strong>Export&nbsp;</strong>
                  <div id="prgLoading" className="hide"><ReactLoading type="spin" color="white" height={15} width={15} /></div>
                </button>
                <button
                  className="panel-iconbutton-search"
                  onClick={() => {
                    searchButtonHandler();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="20px"
                    height="20px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                  </svg>
                  <strong>Search</strong>
                </button>
              </div>
            </div>
          </PanelBody>
          <PanelBody>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td>
                      <strong>Options</strong>
                    </td>
                    <td>
                      <strong>ID: Number</strong>
                    </td>
                    <td>
                      <strong>Customer Name</strong>
                    </td>
                    <td>
                      <strong>ID Status</strong>
                    </td>
                    <td>
                      <strong>Name Status</strong>
                    </td>
                    <td>
                      <strong>Bank Account</strong>
                    </td>
                    <td>
                      <strong>Account # Status</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {typeof customerData !== "undefined" && customerData !== null
                    ? typeof customerData.data !== "undefined" &&
                      customerData.data !== null
                      ? customerData.data.map((value: any, index: any) => {
                          return (
                            <tr
                              key={index}
                              className={
                                index % 2 !== 0
                                  ? "table-data"
                                  : "table-data-alter"
                              }
                            >
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <Link to={`${url}/view/${value.customer_id}`}>
                                    <button
                                      className="form-button-search"
                                      onClick={() => {
                                        //searchIconHandler(line);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="-3 -2 30 30"
                                        fill="white"
                                        width="18px"
                                        height="18px"
                                      >
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                      </svg>
                                    </button>
                                  </Link>
                                  {[3, 5].includes(
                                    PageSettingsContext.userDataLogin.userData
                                      .user_tipo_id
                                  ) ? (
                                    <Link
                                      to={`${url}/edit/${value.customer_id}`}
                                    >
                                      <button
                                        className="form-button-edit"
                                        onClick={() => {
                                          setcustomerToHandle(
                                            value.customer_id
                                          );
                                          editButtonHandler();
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="-1 3 24 24"
                                          fill="white"
                                          width="17px"
                                          height="14px"
                                        >
                                          <path
                                            d="M0 0h24v24H0V0z"
                                            fill="none"
                                          />
                                          <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
                                        </svg>
                                      </button>
                                    </Link>
                                  ) : null}
                                  {[3, 5].includes(
                                    PageSettingsContext.userDataLogin.userData
                                      .user_tipo_id
                                  ) ? (
                                    <button
                                      className="form-button-delete"
                                      onClick={() => {
                                        setcustomerToHandle(value.customer_id);
                                        deleteButtonHandler();
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0.5 2 24 24"
                                        fill="white"
                                        width="16px"
                                        height="16px"
                                      >
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z" />
                                      </svg>
                                    </button>
                                  ) : null}
                                </div>
                              </td>
                              <td>{`${value.customer_id_type} ${value.customer_id_number}`}</td>
                              <td>{value.customer_name}</td>
                              <td>{value.kyc_status_name1}</td>
                              <td>{value.kyc_status_name2}</td>
                              <td>{value.bank_account_cci?value.bank_account_cci:value.bank_account_number}</td>
                              <td>{value.kyc_status_name3}</td>
                            </tr>
                          );
                        })
                      : null
                    : loadingAnimation()}
                </tbody>
              </table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <ul className="pagination mb-0">
                <li className="page-item">
                  <button className="page-link" onClick={() => gotoPage(0)}>
                    <i className="fa fa-angle-double-left"></i>
                  </button>
                </li>
                <li className="page-item">
                  <button className="page-link" onClick={() => previousPage()}>
                    <i className="fa fa-angle-left"></i>
                  </button>
                </li>
                <li className="page-item d-flex align-items-center px-2">
                  <div>
                    Page{" "}
                    {typeof customerData !== "undefined" &&
                    customerData !== null ? (
                      typeof customerData.data !== "undefined" &&
                      customerData.data !== null ? (
                        <strong>
                          {page + 1} of {customerData.last_page}
                        </strong>
                      ) : (
                        <strong>{page + 1} of 1</strong>
                      )
                    ) : (
                      <strong>{page + 1} of 1</strong>
                    )}
                  </div>
                </li>
                <li className="page-item">
                  <button className="page-link" onClick={() => nextPage()}>
                    <i className="fa fa-angle-right"></i>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => gotoPage(customerData.last_page - 1)}
                  >
                    <i className="fa fa-angle-double-right"></i>
                  </button>
                </li>
              </ul>
              {typeof customerData !== "undefined" &&
              customerData !== null ? (
                typeof customerData.data !== "undefined" &&
                customerData.data !== null ? (
                  <strong>
                    <div className="ml-3 mr-1 mx-2">{`Showing ${
                      customerData.per_page * customerData.current_page -
                      (customerData.per_page - 1)
                    } to ${
                      customerData.per_page * customerData.current_page
                    } of ${customerData.total_count} entries`}</div>
                  </strong>
                ) : (
                  <div className="ml-3 mr-1">{`Showing 0 to 0 of 0 entries`}</div>
                )
              ) : (
                <div className="ml-3 mr-1">{`Showing 0 to 0 of 0 entries`}</div>
              )}
              <div className="ml-3 mr-1">Go to page:</div>
              <div className="mx-2">
                <input
                  className="form-control"
                  type="number"
                  style={{ width:70 }}
                  defaultValue={page + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                />
              </div>
              <div>
                <select
                  className="form-select"
                  value={per_page}
                  onChange={(e) => {
                    set_per_page(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50, 60].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default CustomerList;
