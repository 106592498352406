import React from "react";
import HttpCliente from "../../../services/HttpCliente";
import fileDownload from "js-file-download";
import ReactLoading from 'react-loading';
import axios from "axios";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel.jsx";
import { Link } from "react-router-dom";
import { AppSettings } from "../../../config/app-settings";
import ModalGeneral, { ModalGeneralType } from "../../../components/modal/modalGeneral";
import DatePicker from "react-datepicker";
import { useRef } from "react";

const CustomerGreyList = () => {
  const PageSettingsContext = React.useContext(AppSettings);
  const url = window.location.pathname;

  const [per_page, set_per_page] = React.useState(50);
  const [page, set_page] = React.useState(0);
  const [customerData, setCustomersData] = React.useState<any>([]);
  const [bankData, setBankData] = React.useState([]);
  //////////////////////////////////////////////////////////////////INPUT FIELDS/////////////////////////////////////////////////////////////
  const [document_type, set_document_type] = React.useState(
    PageSettingsContext.document_type_cs
  );
  const [document_number, set_document_number] = React.useState(
    PageSettingsContext.document_number_cs
  );
  const [bank_account, set_bank_account] = React.useState(
    PageSettingsContext.bank_account_cs
  );
  const [bank_account_currency, set_bank_account_currency] = React.useState(
    PageSettingsContext.bank_account_currency_cs
  );
  const [date_from, set_date_from] = React.useState(
    PageSettingsContext.date_from_cs
  );
  const [date_to, set_date_to] = React.useState(
    PageSettingsContext.date_to_cs
  );
  const [payment_id, set_payment_id] = React.useState(
    PageSettingsContext.payment_id_cs
  );
  const [payment_bank_code, set_payment_bank_code] = React.useState(
    PageSettingsContext.payment_bank_code_cs
  );
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [toggleRender, setToggleRender] = React.useState(false);
  const [alertType, setAlertType] = React.useState("");
  const [bank_account_ToHandle, set_bank_account_ToHandle] = React.useState("");
  const [sweetAlertActive, setSweetAlertActive] = React.useState(false);
  const [sweetAlertMessage, setSweetAlertMessage] = React.useState("Default");
  const [sweetAlertSuccess, setSweetAlertSuccess] = React.useState<ModalGeneralType>("info");
  const [sweetAlertSuccessBool, setSweetAlertSuccessBool] = React.useState(
    false
  );
  const [
    sweetAlertActiveVariant2,
    setSweetAlertActiveVariant2,
  ] = React.useState(false);
  const [
    sweetAlertMessage1Variant2,
    setSweetAlertMessage1Variant2,
  ] = React.useState("Temp");
  const [
    sweetAlertMessage2Variant2,
    setSweetAlertMessage2Variant2,
  ] = React.useState("Default");
  const [
    sweetAlertActiveVariant3,
    setSweetAlertActiveVariant3,
  ] = React.useState(false);
  const sweetAlertMessage1Variant3 = "Temp";
  // const [
  //   sweetAlertMessage1Variant3,
  //   setSweetAlertMessage1Variant3,
  // ] = React.useState("Temp");
  const deleteButtonHandler = () => {
    setAlertType("deleteCustomer");
    toggleSweetAlertVariant2(
      "Are you sure?",
      "Do you want to delete this invalid bank account?"
    );
  };
  const deleteActionProceed = () => {
    axios
      .delete(`/invalidBankAccount/${bank_account_ToHandle}`)
      .then((response) => {
        //console.log(response);
        set_bank_account_ToHandle("");
        setToggleRender(!toggleRender);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const clearButtonHandler = () => {
    setCustomersData(null);
    set_document_number("");
    set_bank_account("");
    set_payment_id("");
    set_date_to(undefined);
    set_date_from(undefined);
    set_document_type("");
    set_bank_account_currency("");
    set_payment_bank_code("");
    PageSettingsContext.set_document_number_cs("");
    PageSettingsContext.set_bank_account_cs("");
    PageSettingsContext.set_document_type_cs("");
    PageSettingsContext.set_bank_account_currency_cs("");
    PageSettingsContext.set_date_from_cs(undefined);
    PageSettingsContext.set_date_to_cs(undefined);
    PageSettingsContext.set_payment_id_cs("");
    PageSettingsContext.set_payment_bank_code_cs("");
    setToggleRender(!toggleRender);
  };

  function formatDateToShortDateString(date: Date | null | undefined): string {
    if (!date) {
      return '';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  ////////////////////////////////////////////////PAGINATION HANDLERS////////////////////////////////////////////////////////////////////////////////////////

  const gotoPage = (value: any) => {
    set_page(value);
  };
  const nextPage = () => {
    if (typeof customerData !== "undefined") {
      if (page < customerData.last_page - 1) {
        set_page(page + 1);
      }
    }
  };
  const previousPage = () => {
    if (page > 0) set_page(page - 1);
  };
  ////////////////////////////////////////////////LOADING ANIMATION FUNCTION////////////////////////////////////////////////////////////////////////////////////////
  const loadingAnimation = () => {
    return (
      <tr>
        <td colSpan={9}>
          <div style={{ padding: "15vh 0% 15vh 38vw" }}>
            <ReactLoading type="spin" color="#00aeab" height={65} width={65} />
          </div>
        </td>
      </tr>
    );
  };
  const searchButtonHandler = () => {
    setCustomersData(null);
    HttpCliente.get(
      `/invalidBankAccount/?payment_id=${payment_id}&document_number=${document_number}&account_number=${bank_account
      }&document_type=${document_type}&account_currency=${bank_account_currency}&date_from=${formatDateToShortDateString(date_from)
      }&date_to=${formatDateToShortDateString(date_to)}&per_page=${per_page}&page=${page + 1}&payment_bank_code=${payment_bank_code}`
    )
      .then((response) => {
        setCustomersData(response.data);
        //console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////////////////////////////////////////////////SWEET ALERTS TOGGLE HANDLER////////////////////////////////////////////////////////////////////////////////////////
  const toggleSweetAlert = (message: string, success: any, type: ModalGeneralType) => {
    setSweetAlertSuccessBool(success);
    setSweetAlertSuccess(type);
    setSweetAlertActive(!sweetAlertActive);
    setSweetAlertMessage(message);
  };

  const toggleSweetAlertVariant2 = (message: any, message2: any) => {
    setSweetAlertActiveVariant2(!sweetAlertActiveVariant2);
    setSweetAlertMessage1Variant2(message);
    setSweetAlertMessage2Variant2(message2);
  };

  React.useEffect(() => {
    searchButtonHandler();
    HttpCliente.get(`/bank`)
      .then((response) => {
        setBankData(response.data);
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [toggleRender, page, per_page]);

  let titlePage = "Grey List";
  return (
    <div id="animate">
      <div>
        {sweetAlertActive ? (
          <ModalGeneral
            isModalOpen={sweetAlertActive}
            onlyModal={false}
            type={`${sweetAlertSuccess}`}
            onConfirm={() => {
              setSweetAlertActive(false);
            }}
            onClose={() => {
              setSweetAlertActive(false);
            }}
          >
            <div>
              <h3>{sweetAlertMessage}</h3>
            </div>
          </ModalGeneral>
        ) : null}
        {sweetAlertActiveVariant2 ? (
          <ModalGeneral
            isModalOpen={sweetAlertActiveVariant2}
            onlyModal={false}
            type="info"
            title={sweetAlertMessage1Variant2}
            onConfirm={() => {
              if (alertType === "deleteCustomer") {
                deleteActionProceed();
              }
              setSweetAlertActiveVariant2(false);
            }}
            onCancel={() => {
              if (alertType !== "nextRequest") {
              } else {
              }
              setAlertType("");
              setSweetAlertActiveVariant2(false);
            }}
            cancelBtnText="Cancel"
            confirmBtnText="Yes"
          >
            <h5>{sweetAlertMessage2Variant2}</h5>
          </ModalGeneral>
        ) : null}
        {sweetAlertActiveVariant3 ? (
          <ModalGeneral
            isModalOpen={sweetAlertActiveVariant3}
            onlyModal={false}
            title={sweetAlertMessage1Variant3}
            type="info"
            onConfirm={() => {
              if (alertType === "deleteCustomer") {
                deleteActionProceed();
              }
              setSweetAlertActiveVariant3(false);
            }}
            onCancel={() => {
              setAlertType("");
              setSweetAlertActiveVariant3(false);
            }}
            cancelBtnText="Cancel"
            confirmBtnText="Yes"
          />
        ) : null}
        <h1 className="page-header">{titlePage}</h1>
        <Panel>
          <PanelHeader noButton>Filter By</PanelHeader>
          <PanelBody>
            <div className="row">
              <div className="col-md-3">
                <label className="form-label" htmlFor="InputPaymentId">
                  <strong>Payment ID</strong>
                </label>
                <input
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      searchButtonHandler();
                    }
                  }}
                  type="text"
                  className="form-control"
                  id="InputPaymentId"
                  placeholder="ID"
                  value={payment_id}
                  onChange={(e) => {
                    set_payment_id(e.target.value);
                    PageSettingsContext.set_payment_id_cs(e.target.value);
                  }}
                />
              </div>
              <div className="mb-3 col-md-3">
                <label className="form-label">
                  <strong>Document Type</strong>
                </label>
                <br />
                <select
                  className="form-select"
                  name="document-type-select"
                  id="InputDocumentType"
                  value={document_type}
                  onChange={(e) => {
                    set_document_type(e.target.value);
                    PageSettingsContext.set_document_type_cs(e.target.value);
                  }}
                >
                  <option value="" disabled hidden style={{ color: "gray" }}>
                    Select..
                  </option>
                  <option value={"DNI"} key={"dni"}>
                    DNI
                  </option>
                  <option value={"CE"} key={"ce"}>
                    CE
                  </option>
                  <option value={"PAS"} key={"pas"}>
                    PAS
                  </option>
                </select>
              </div>
              <div className="col-md-3">
                <label className="form-label" htmlFor="InputCustomer">
                  <strong>Document Number</strong>
                </label>
                <input
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      searchButtonHandler();
                    }
                  }}
                  type="text"
                  className="form-control"
                  id="InputDocumentNumber"
                  placeholder="DNI"
                  value={document_number}
                  onChange={(e) => {
                    set_document_number(e.target.value);
                    PageSettingsContext.set_document_number_cs(e.target.value);
                  }}
                />
              </div>
              <div className="mb-3 col-md-3">
                <label className="form-label">
                  <strong>Currency</strong>
                </label>
                <br />
                <select
                  className="form-select"
                  name="currency-select"
                  id="InputCurrency"
                  placeholder="Select Currency"
                  value={bank_account_currency}
                  onChange={(e) => {
                    set_bank_account_currency(e.target.value);
                    PageSettingsContext.set_bank_account_currency_cs(e.target.value);
                  }}
                >
                  <option value="" disabled hidden style={{ color: "gray" }}>
                    Select..
                  </option>
                  <option value={"PEN"} key={"pen"}>
                    PEN
                  </option>
                  <option value={"USD"} key={"usd"}>
                    USD
                  </option>
                </select>
              </div>
              <div className="col-md-3">
                <label className="form-label" htmlFor="InputBankAccount">
                  <strong>Bank Account</strong>
                </label>
                <input
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      searchButtonHandler();
                    }
                  }}
                  type="text"
                  className="form-control"
                  id="InputBankAccount"
                  placeholder="Local / CCI"
                  value={bank_account}
                  onChange={(e) => {
                    set_bank_account(e.target.value);
                    PageSettingsContext.set_bank_account_cs(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <label className="form-label">
                  <strong>Date From</strong>
                </label>
                <DatePicker
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchButtonHandler();
                    }
                  }}
                  className="form-control"
                  id="InputDateFrom"
                  placeholderText={"Select a date"}
                  maxDate={date_to}
                  selected={date_from}
                  dateFormat="dd/MM/yyyy"
                  onChange={(e) => {
                    set_date_from(e);
                    PageSettingsContext.set_date_from_cs(e);
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="col-md-3">
                <label className="form-label">
                  <strong>Date To</strong>
                </label>
                <DatePicker
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchButtonHandler();
                    }
                  }}
                  className="form-control"
                  id="InputDateTo"
                  placeholderText={"Select a date"}
                  minDate={date_from}
                  selected={date_to}
                  dateFormat="dd/MM/yyyy"
                  onChange={(e) => {
                    set_date_to(e);
                    PageSettingsContext.set_date_to_cs(e);
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="col-md-3 mb-3">
                <label className="form-label" htmlFor="InputBank">
                  <strong>Bank</strong>
                </label>
                <br />
                <select
                  className="form-select"
                  name="bank"
                  id="InputBank"
                  value={payment_bank_code}
                  onChange={(e) => {
                    set_payment_bank_code(e.target.value);
                  }}
                >
                  <option value="" disabled hidden>
                    Select Bank
                  </option>
                  {typeof bankData !== `undefined`
                    ? bankData.map((value: any, index: any) => {
                      return (
                        <option value={value.bankCode} key={value.bankCode}>
                          {value.bankName}
                        </option>
                      );
                    })
                    : null}
                </select>
              </div>
            </div>
            <div
              className="panel-button-row"
              style={{ padding: "0px 5px 0px 15px" }}
            >
              <div />
              <div>
                <button
                  className="panel-iconbutton-clear"
                  onClick={() => {
                    clearButtonHandler();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="20px"
                    height="20px"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z" />
                  </svg>
                  <strong>Clear</strong>
                </button>
                <button
                  className="panel-iconbutton-search"
                  onClick={() => {
                    searchButtonHandler();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="20px"
                    height="20px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                  </svg>
                  <strong>Search</strong>
                </button>
              </div>
            </div>
          </PanelBody>
          <PanelBody>
            <div className="table-responsive">
              <table className="table table-bordered" style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    <td style={{ width: "5%" }}>
                      <strong>Options</strong>
                    </td>
                    <td style={{ width: "10%" }}>
                      <strong>ID: Number</strong>
                    </td>
                    <td style={{ width: "5%" }}> 
                      <strong>Currency</strong>
                    </td>
                    <td style={{ width: "15%" }}>
                      <strong>Bank Account</strong>
                    </td>
                    <td style={{ width: "13%" }}>
                      <strong>Bank</strong>
                    </td>
                    <td style={{ width: "35%" }}>
                      <strong>Reason</strong>
                    </td>
                    <td style={{ width: "5%" }}>
                      <strong>Register Date</strong>
                    </td>
                    <td style={{ width: "5%" }}>
                      <strong>Payment ID</strong>
                    </td>
                    <td style={{ width: "7%" }}>
                      <strong>Amount</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {typeof customerData !== "undefined" && customerData !== null
                    ? typeof customerData.data !== "undefined" &&
                      customerData.data !== null
                      ? customerData.data.map((value: any, index: any) => {
                        return (
                          <tr
                            key={index}
                            className={
                              index % 2 !== 0
                                ? "table-data"
                                : "table-data-alter"
                            }
                          >
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                {[3, 5].includes(
                                  PageSettingsContext.userDataLogin.userData
                                    .user_tipo_id
                                ) ? (
                                  <button
                                    className="form-button-delete"
                                    onClick={() => {
                                      set_bank_account_ToHandle(value.id);
                                      deleteButtonHandler();
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0.5 2 24 24"
                                      fill="white"
                                      width="16px"
                                      height="16px"
                                    >
                                      <path d="M0 0h24v24H0V0z" fill="none" />
                                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z" />
                                    </svg>
                                  </button>
                                ) : null}
                              </div>
                            </td>
                            <td>{`${value.document_type} ${value.document_number}`}</td>
                            <td>{value.bank_account_currency}</td>
                            <td>{value.bank_account_number}</td>
                            <td>{`${value.payment_bank_name ?? ""} `}</td>
                            <td>{value.reject_reason}</td>
                            <td>{value.register_date}</td>
                            <td>{value.payment_id}</td>
                            <td>{value.payment_amount}</td>
                          </tr>
                        );
                      })
                      : null
                    : loadingAnimation()}
                </tbody>
              </table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <ul className="pagination mb-0">
                <li className="page-item">
                  <button className="page-link" onClick={() => gotoPage(0)}>
                    <i className="fa fa-angle-double-left"></i>
                  </button>
                </li>
                <li className="page-item">
                  <button className="page-link" onClick={() => previousPage()}>
                    <i className="fa fa-angle-left"></i>
                  </button>
                </li>
                <li className="page-item d-flex align-items-center px-2">
                  <div>
                    Page{" "}
                    {typeof customerData !== "undefined" &&
                      customerData !== null ? (
                      typeof customerData.data !== "undefined" &&
                        customerData.data !== null ? (
                        <strong>
                          {page + 1} of {customerData.last_page}
                        </strong>
                      ) : (
                        <strong>{page + 1} of 1</strong>
                      )
                    ) : (
                      <strong>{page + 1} of 1</strong>
                    )}
                  </div>
                </li>
                <li className="page-item">
                  <button className="page-link" onClick={() => nextPage()}>
                    <i className="fa fa-angle-right"></i>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => gotoPage(customerData.last_page - 1)}
                  >
                    <i className="fa fa-angle-double-right"></i>
                  </button>
                </li>
              </ul>
              {typeof customerData !== "undefined" &&
                customerData !== null ? (
                typeof customerData.data !== "undefined" &&
                  customerData.data !== null ? (
                  <strong>
                    <div className="ml-3 mr-1 mx-2">{`Showing ${customerData.per_page * customerData.current_page -
                      (customerData.per_page - 1)
                      } to ${customerData.per_page * customerData.current_page
                      } of ${customerData.total_count} entries`}</div>
                  </strong>
                ) : (
                  <div className="ml-3 mr-1">{`Showing 0 to 0 of 0 entries`}</div>
                )
              ) : (
                <div className="ml-3 mr-1">{`Showing 0 to 0 of 0 entries`}</div>
              )}
              <div className="ml-3 mr-1">Go to page:</div>
              <div className="mx-2">
                <input
                  className="form-control"
                  type="number"
                  style={{ width: 70 }}
                  defaultValue={page + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                />
              </div>
              <div>
                <select
                  className="form-select"
                  value={per_page}
                  onChange={(e) => {
                    set_per_page(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50, 60].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default CustomerGreyList;
