import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate  } from "react-router-dom";
import { AppSettings } from "../../config/app-settings";
import { loginUser } from "../../actions/AuthAction";
import { storageInteractor } from "../../utils/storageInteractor";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

type LoginParm = {
  user_name?: string;
  password?: string;
  g_recaptcha_response?: string;
  showValidation? : boolean;
  messageValidation?: string | undefined;
}
const objStorageInteractor = new storageInteractor();

export default function Login(): React.ReactElement {  
const REACT_APP_ReCaptchaKey: string = process.env.REACT_APP_ReCaptchaKey!;
  return (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_ReCaptchaKey} scriptProps={{ async: true }}>
      <LoginForm />
    </GoogleReCaptchaProvider>
  );
}

function LoginForm() {
  const PageSettingsContext = React.useContext(AppSettings);
  const [loginParm, setLoginParm] = useState<LoginParm>({showValidation: false, messageValidation: ''});
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    let token_security = objStorageInteractor.getTokenSecurity();
    let token_security_tmp = objStorageInteractor.getTokenSecurityTmp();
		if (token_security != null && token_security_tmp == null) { navigate(`${process.env.REACT_APP_Url_Home}`);}

    PageSettingsContext.handleSetAppSidebarNone(true);
    PageSettingsContext.handleSetAppHeaderNone(true);
    PageSettingsContext.handleSetAppContentClass('p-0');

    return() => {
      PageSettingsContext.handleSetAppSidebarNone(false);
      PageSettingsContext.handleSetAppHeaderNone(false);
      PageSettingsContext.handleSetAppContentClass('');
    }
  }, []);


  
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token_ = await executeRecaptcha('login');   
    return token_;
  }, [executeRecaptcha]);



  
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const token_= await handleReCaptchaVerify();     
    let updatedValue = {...loginParm};
    updatedValue['g_recaptcha_response']= token_;
    setLoginParm(updatedValue);

    loginUser(updatedValue).then(async (response: any) => {
      if (response.status === 200)
      {
        setLoginParm({});

        if (response.data.required_duo)
        {
          objStorageInteractor.setTokenSecurityTmp(generateGuidSegment(2));
          PageSettingsContext.handleSetUserDataLogin(response, true, false);
          navigate(`${process.env.REACT_APP_Sub_Domain}/DuoAuth`);
        }
        else
        {
          objStorageInteractor.setTokenSecurity(response.data.token);
          PageSettingsContext.handleSetUserDataLogin(response, true, true);
          navigate(`${process.env.REACT_APP_Url_Home}`);
        }
      }
      else
      {          
        setLoginParm({...loginParm, ...{showValidation: true, messageValidation: response.data.errors.message}});
      }
    });
  }

  
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let updatedValue = {};
    e.currentTarget.id === "user_name" ? updatedValue = { user_name: e.currentTarget.value } : updatedValue = { password: e.currentTarget.value };
    setLoginParm({...loginParm, ...updatedValue });
  };  


  const generateGuidSegment = (count: number): string => {
    let out = '';
    for (let i = 0; i < count; i++) {
      // tslint:disable-next-line:no-bitwise
      out += (((1 + Math.random()) * 0x10000) | 0)
        .toString(16)
        .substring(1)
        .toLowerCase();
    }
    return out;
  };

  const ShowValidation = () => (
    <div className="text-danger">
        <p>{loginParm!.messageValidation}</p>
    </div>
  )

  return (
    <div className="login login-v1">
      <div className="login-container">
        <div className="login-header">
          <div className="brand">
            <div className="d-flex align-items-center">
              <span className="logo"></span><b>KYC Login</b>
            </div>
          </div>
          <div className="icon">
            <i className="fa fa-lock"></i>
          </div>
        </div>
        <div className="login-body">
          <div className="login-content fs-13px">
            <form onSubmit={handleSubmit}>
              { loginParm.showValidation ? <ShowValidation /> : '' }
              <div className="form-floating mb-20px">
                <input type="text" className="form-control fs-13px h-45px" id="user_name" placeholder="User Name" onChange={handleChangeInput} />
                <label htmlFor="user_name" className="d-flex align-items-center py-0">User Name</label>
              </div>
              <div className="form-floating mb-20px">
                <input type="password" className="form-control fs-13px h-45px" id="password" placeholder="Password" onChange={handleChangeInput} />
                <label htmlFor="password" className="d-flex align-items-center py-0">Password</label>
              </div>
              <div className="login-buttons">
                <button type="submit" className="btn h-45px btn-success d-block w-100 btn-lg">Sign me in</button>
              </div>                  
            </form>
          </div>
        </div>
        <div className="text-center login-copyright mt-2">
          @{(new Date().getFullYear())} {process.env.REACT_APP_CompanyName}
        </div>
      </div>
    </div>
  )
};