import "./style.scss";
import React from "react";
import { Panel, PanelHeader, PanelBody } from "../panel/panel";
import HttpCliente from "../../services/HttpCliente";
import axios from "axios";

const AddEditBankAccountPopup = ({
  setBankEditPopup,
  bankEditMode,
  bankViewMode,
  bankEditModeBank,
  toggleSweetAlert,
  customerId,
  customerData,
}) => {
  const [customer_bank_id, set_customer_bank_id] = React.useState("");
  const [customer_id, set_customer_id] = React.useState("");
  const [bank_account_cci, set_bank_account_cci] = React.useState("");
  const [bank_abbrev_name, set_bank_abbrev_name] = React.useState("");
  const [bank_account_number, set_bank_account_number] = React.useState("");
  const [bank_account_currency, set_bank_account_currency] = React.useState("");
  const [kyc_status_bank_account, set_kyc_status_bank_account] =
    React.useState("");
  const [kyc_status_bank_name, set_kyc_status_bank_name] = React.useState("");
  const [kyc_status_bank_code, set_kyc_status_bank_code] = React.useState("");
  const [statusKycData, setStatusKycData] = React.useState([]);
  // const [customerData, setCustomerData] = React.useState([]);
  const [bankData, setBankData] = React.useState([]);
  const bankNameAndAccountHandler = (bank_cci) => {
    let fullString = `${bank_cci}`;
    let subString = fullString.substring(0, 3);
    let bankCode;
    HttpCliente.get(`/bank`)
      .then((response) => {
        //console.log(response);
        setBankData(response.data);
      })
      .catch((error) => {
        //console.log(error.response);
      });
    if (typeof bankData !== `undefined`) {
      bankData.map((value, index) => {
        if (value.bankCode === subString) {
          bankCode = value.bankCode;
          if (value.abbrevName !== null) set_bank_abbrev_name(value.abbrevName);
          else set_bank_abbrev_name(value.bankName);
        }
      });
    }
    if (bankCode !== undefined && bank_cci !== undefined)
      HttpCliente.get(`/bank/${bankCode}/${bank_cci}`)
        .then((response) => {
          if (response.status === 200) {
            set_bank_account_number(response.data.account_number);
            set_bank_account_currency(response.data.account_currency);
          }
        })
        .catch((error) => {
          //console.log(error.response);
        });
  };

  React.useEffect(() => {
    set_customer_bank_id(bankEditModeBank.customer_bank_id);
    set_customer_id(bankEditModeBank.customer_id);
    if (bankEditMode === true) {
      set_bank_account_cci(bankEditModeBank.bank_account_cci);
      set_bank_abbrev_name(bankEditModeBank.bank_abbrev_name);
      set_bank_account_number(bankEditModeBank.bank_account_number);
      set_bank_account_currency(bankEditModeBank.bank_account_currency);
      set_kyc_status_bank_account(bankEditModeBank.kyc_status_bank_account);
      set_kyc_status_bank_name(bankEditModeBank.kyc_status_bank_name);
      set_kyc_status_bank_code(bankEditModeBank.kyc_status_bank_code);
    }
    HttpCliente.get(`/status/kyc`)
      .then((response) => {
        setStatusKycData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });

    // HttpCliente.get(`/customer`)
    //   .then((response) => {
    //     setCustomerData(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
  }, []);

  const onSaveHandler = () => {
    let sigData;
    // let temp = false;
    // customerData.data.map((value) => {
    //   if (
    //     bank_account_number === value.bank_account_number ||
    //     bank_account_cci === value.bank_account_cci
    //   )
    //     temp = true;
    // });

    // if (temp) {
    //   toggleSweetAlert("Bank CCI is duplicated, please check", false, "info");
    // } else {
    if (bankEditMode === true) {
      sigData = {
        kyc_status_bank_name: parseInt(kyc_status_bank_name, 10),
        kyc_status_bank_account: parseInt(kyc_status_bank_account, 10),
        bank_account_number: bank_account_number,
        bank_account_cci: bank_account_cci,
        bank_account_currency: bank_account_currency,
        bank_code: bank_account_cci.substr(0, 3),
      };
    } else {
      sigData = {
        // bank_account_number: parseInt(bank_account_number, 10),
        // bank_account_cci: parseInt(bank_account_cci, 10),
        // bank_account_currency,
        kyc_status_bank_name: parseInt(kyc_status_bank_name, 10),
        kyc_status_bank_account: parseInt(kyc_status_bank_account, 10),
        bank_account_number: bank_account_number,
        bank_account_cci: bank_account_cci,
        bank_account_currency: bank_account_currency,
        bank_code: bank_account_cci.substr(0, 3),
      };
    }

    if (
      (bank_account_cci === "" && bank_account_number === "") ||
      kyc_status_bank_account === "" ||
      kyc_status_bank_name === ""
    ) {
      toggleSweetAlert("Please complete all fields", false, "info");
    } else {
      if (bankEditMode === false) {
        axios
          .post(`/customer/${customerId}/bank`, sigData)
          .then((response) => {
            //console.log(response);
            toggleSweetAlert("Bank account Added", true, "success");
            setBankEditPopup(false);
          })
          .catch((error) => {
            toggleSweetAlert(`Error: ${error}`, false, "danger");
          });
      } else {
        axios
          .patch(`/customer/${customer_id}/bank/${customer_bank_id}`, sigData)
          .then((response) => {
            //console.log(response);
            toggleSweetAlert("Bank account Updated", true, "success");
            setBankEditPopup(false);
          })
          .catch((error) => {
            toggleSweetAlert(`Error: ${error}`, false, "danger");
          });
      }
    }
    // }
  };

  return (
    <div>
      <div id="animate" className="wrapper">
        <Panel>
          <PanelHeader noButton>{`${
            bankEditMode ? "Edit" : "Add"
          } Bank Account`}</PanelHeader>
          <PanelBody>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label className="form-label" htmlFor="bankCciInput">
                  <strong>Bank CCI</strong>
                </label>
                <input
                  disabled={bankViewMode}
                  type="text"
                  className="form-control"
                  id="bankCciInput"
                  placeholder="Enter Bank CCI"
                  value={bank_account_cci}
                  onChange={(e) => {
                    if (e.target.value.length < 21)
                      set_bank_account_cci(e.target.value);
                  }}
                  onBlur={(e) => {
                    bankNameAndAccountHandler(e.target.value);
                  }}
                  onBlurCapture={(e) => {
                    bankNameAndAccountHandler(e.target.value);
                  }}
                  onFocus={(e) => {
                    bankNameAndAccountHandler(e.target.value);
                  }}
                />
              </div>
              <div className="form-group col-md-6 mb-3">
                <label className="form-label" htmlFor="bankInput">
                  <strong>Bank</strong>
                </label>
                <input
                  disabled={true}
                  type="text"
                  className="form-control"
                  id="bankInput"
                  placeholder="Bank Name"
                  value={bank_abbrev_name}
                />
              </div>
              <div className="form-group col-md-6 mb-3">
                <label className="form-label" htmlFor="bankAccountInput">
                  <strong>Bank Account #</strong>
                </label>
                <input
                  disabled={bankViewMode}
                  type="text"
                  className="form-control"
                  id="bankAccountInput"
                  placeholder="Bank Account Number"
                  value={bank_account_number}
                  onChange={(e) => {
                    set_bank_account_number(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 mb-3">
                <label className="form-label" htmlFor="inputCurrency">
                  <strong>Currency</strong>
                </label>
                <select
                  disabled={bankViewMode}
                  type="text"
                  className="form-select"
                  id="inputCurrency"
                  placeholder="PEN / USD"
                  value={bank_account_currency}
                  onChange={(e) => {
                    set_bank_account_currency(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select Currency
                  </option>
                  <option value={"PEN"} key={"PEN"}>
                    PEN
                  </option>
                  <option value={"USD"} key={"USD"}>
                    USD
                  </option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 mb-3">
                <label className="form-label" htmlFor="inputAccountNumberStatus">
                  <strong>Account #: Status</strong>
                </label>
                <select
                  disabled={bankViewMode}
                  type="text"
                  className="form-select"
                  id="inputAccountNumberStatus"
                  placeholder="Unknown"
                  value={kyc_status_bank_account}
                  onChange={(e) => {
                    set_kyc_status_bank_account(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select Status
                  </option>
                  {typeof statusKycData !== `undefined`
                    ? statusKycData.map((value, index) => (
                        <option
                          value={value.kycStatusId}
                          key={value.kycStatusId}
                        >
                          {value.kycStatusName}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="form-group col-md-6 mb-3">
                <label className="form-label" htmlFor="inputNameStatus">
                  <strong>Name: Status</strong>
                </label>
                <select
                  disabled={bankViewMode}
                  type="text"
                  className="form-select"
                  id="inputNameStatus"
                  placeholder="Unknown"
                  value={kyc_status_bank_name}
                  onChange={(e) => {
                    set_kyc_status_bank_name(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select Status
                  </option>
                  {typeof statusKycData !== `undefined`
                    ? statusKycData.map((value, index) => (
                        <option
                          value={value.kycStatusId}
                          key={value.kycStatusId}
                        >
                          {value.kycStatusName}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </div>
            <div className="panel-button-row">
              <div>
                <button
                  className="panel-iconbutton-clear"
                  onClick={() => {
                    setBankEditPopup(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="18px"
                    height="18px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                  <strong>Cancel</strong>
                </button>
              </div>
              {bankViewMode === false ? (
                <div>
                  <button
                    className="panel-iconbutton-save"
                    onClick={() => {
                      onSaveHandler();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="18px"
                      height="18px"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                    </svg>
                    <strong>Save</strong>
                  </button>
                </div>
              ) : null}
            </div>
            {/* )} */}
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default AddEditBankAccountPopup;
